import { createContext, FC, PropsWithChildren, useMemo, useState } from 'react';

import { IInputPasswordContext } from '../types';

const InputPasswordContext = createContext({} as IInputPasswordContext);

const InputPasswordProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isActive, setIsActive] = useState(false);

  const contextValue = useMemo<IInputPasswordContext>(
    () => ({ isActive, activateInput: () => setIsActive(true), deactivateInput: () => setIsActive(false) }),
    [isActive],
  );

  return <InputPasswordContext.Provider value={contextValue}>{children}</InputPasswordContext.Provider>;
};

export { InputPasswordContext, InputPasswordProvider };
