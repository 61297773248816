import { createContext, FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { IInputMaskContext } from '../types';

const InputMaskContext = createContext({} as IInputMaskContext);

const InputMaskProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isActive, setIsActive] = useState(false);
  const [isCalendarOpened, setCalendarOpened] = useState(false);

  const openCalendar = useCallback(() => {
    setCalendarOpened(true);
  }, []);

  const closeCalendar = useCallback(() => {
    setCalendarOpened(false);
  }, []);

  const contextValue = useMemo<IInputMaskContext>(
    () => ({
      activateInput: () => setIsActive(true),
      closeCalendar,
      deactivateInput: () => setIsActive(false),
      isActive,
      isCalendarOpened,
      openCalendar,
    }),
    [closeCalendar, isActive, isCalendarOpened, openCalendar],
  );

  return <InputMaskContext.Provider value={contextValue}>{children}</InputMaskContext.Provider>;
};

export { InputMaskContext, InputMaskProvider };
