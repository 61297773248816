import { createContext, FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { ISelectMultiOption } from '../types';

export interface ISelectMultiContext {
  disable: boolean;
  isOpened: boolean;
  label: string;
  labelSelected: string;
  value: string;
  optionsOriginal: ISelectMultiOption[];
  options: ISelectMultiOption[];
  optionsSelected: ISelectMultiOption[];
  closeSelectOpen: () => void;
  toggleSelectOpen: () => void;
  handleOptionChange: (selected: ISelectMultiOption) => void;
  handleOptionsChange: (selected: ISelectMultiOption[], reset?: boolean) => void;
  removeItem: (option: ISelectMultiOption) => void;
  handleFilterOptions: (text: string) => void;
  clearSelected: () => void;
}

interface ISelectMultiProps {
  options: ISelectMultiOption[];
  disable?: boolean;
  label?: string;
  labelSelected?: string;
  value?: string;
}

const SelectMultiContext = createContext<ISelectMultiContext>({} as ISelectMultiContext);

const SelectMultiProvider: FC<PropsWithChildren<ISelectMultiProps>> = props => {
  const {
    children,
    label = 'label',
    labelSelected = 'labelSelected',
    value = 'value',
    options = [],
    disable = false,
  } = props;

  const [isOpened, setOpened] = useState(false);
  const [optionsSelected, setOptionsSelected] = useState<ISelectMultiOption[]>([]);
  const [opts, setOpts] = useState<ISelectMultiOption[]>(options);

  const toggleSelectOpen = useCallback(() => {
    setOpened(current => !current);
  }, []);

  const closeSelectOpen = useCallback(() => {
    setOpened(false);
    setOpts(options);
  }, [options]);

  const handleOptionChange = useCallback(
    (selected: ISelectMultiOption) => {
      setOptionsSelected(current =>
        current.some(o => o[value] === selected[value])
          ? current.filter(o => o[value] !== selected[value])
          : [...current, selected],
      );
    },
    [value],
  );

  const handleOptionsChange = useCallback((selected: ISelectMultiOption[], reset?: boolean) => {
    setOptionsSelected(current => (reset ? selected : [...current, ...selected]));
  }, []);

  const removeItem = useCallback(
    (option: ISelectMultiOption) => {
      setOptionsSelected(current => current.filter(o => o[value] !== option[value]));
    },
    [value],
  );

  const handleFilterOptions = useCallback(
    (text: string) => {
      setOpts(options.filter(opt => opt[label].toLowerCase().includes(text.toLowerCase())));
    },
    [label, options],
  );

  const clearSelected = useCallback(() => {
    setOptionsSelected([]);
  }, []);

  const contextValue = useMemo<ISelectMultiContext>(
    () => ({
      clearSelected,
      closeSelectOpen,
      disable,
      handleFilterOptions,
      handleOptionChange,
      handleOptionsChange,
      label,
      labelSelected,
      isOpened,
      options: opts,
      optionsOriginal: options,
      optionsSelected,
      removeItem,
      toggleSelectOpen,
      value,
    }),
    [
      clearSelected,
      closeSelectOpen,
      disable,
      handleFilterOptions,
      handleOptionChange,
      handleOptionsChange,
      label,
      labelSelected,
      isOpened,
      options,
      optionsSelected,
      opts,
      removeItem,
      toggleSelectOpen,
      value,
    ],
  );

  return <SelectMultiContext.Provider value={contextValue}>{children}</SelectMultiContext.Provider>;
};

export { SelectMultiContext, SelectMultiProvider };
