import { forwardRef, ForwardRefRenderFunction, ReactElement, useCallback, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Barn, User, X } from '@phosphor-icons/react';
import { format, parseISO } from 'date-fns';

import { Avatar } from '@shared/components/Avatar';
import { Chip } from '@shared/components/Chip';
import { Divider } from '@shared/components/Divider';
import { ModalDefault } from '@shared/components/ModalDefault';
import { Text } from '@shared/components/Text';

import { useFetchAssociationCompanyPublic } from '@modules/companies/hooks/useFetchAssociationCompanyPublic';

import { AssociationRiderByCompanySlug } from '@modules/riders/types/AssociationRidersPublic/public';

type IProps = {
  rider: AssociationRiderByCompanySlug;
};

export interface IModalPublicRiderDetailsHandles {
  open: () => void;
  close: () => void;
}

type IModalPublicRiderDetails = ForwardRefRenderFunction<IModalPublicRiderDetailsHandles, IProps>;

const ModalPublicRiderDetailsComponent: IModalPublicRiderDetails = ({ rider }, ref): ReactElement => {
  const { t } = useTranslation('public-pages');

  const { data: association } = useFetchAssociationCompanyPublic();

  const [visible, setVisible] = useState(false);

  const open = useCallback(() => {
    setVisible(true);
  }, []);

  const close = useCallback(() => {
    setVisible(false);
  }, []);

  useImperativeHandle(ref, () => {
    return { open, close };
  });

  return (
    <ModalDefault visible={visible} close={close}>
      {rider.name ? (
        <div className="relative flex flex-col items-center justify-center overflow-visible lg:w-96">
          <X
            size={16}
            className="absolute right-0 top-0 cursor-pointer text-gray-500 dark:text-gray-600"
            onClick={close}
          />

          <Avatar.Root mode="SQUARE" size="md">
            <Avatar.Content altImg={association?.name} url={association?.logoUrl} icon={Barn} />
          </Avatar.Root>

          <section className="mt-1 flex w-full flex-col items-center gap-2 rounded-lg p-2 shadow-lg">
            <User size={40} className="text-green-500" />
            <Text className="text-xl font-bold text-black-400 dark:text-white">{rider.name}</Text>
            <Text className="text-xs font-semibold text-gray-500 dark:text-gray-600">
              {`ID ${association?.slug}: ${rider.registrationNumber.split('-')[1]}`}
            </Text>
          </section>

          {rider.company.city && (
            <section className="mt-6 flex flex-col items-center gap-1">
              <Text className="text-xs text-gray-500 dark:text-gray-600">{t('location')}</Text>
              <Text className="font-bold text-black-400 dark:text-white">{`${rider.company.city}/${rider.company.state}`}</Text>
            </section>
          )}

          {rider.categories.length > 0 && (
            <ul className="mt-6 flex flex-wrap justify-center gap-1">
              {rider.categories.map(category => (
                <li key={category.id}>
                  <Chip color="green" text={`${category.modality.name}: ${category.name}`} />
                </li>
              ))}
            </ul>
          )}

          <Divider mode="full" color="gray" className="my-4" />

          <section className="flex flex-col items-center gap-2">
            <div className="flex items-center justify-center gap-2">
              <Avatar.Root mode="ROUNDED" size="xxs">
                <Avatar.Content altImg={rider.company.name} url={rider.company.logoUrl} icon={Barn} />
              </Avatar.Root>

              <Text className="text-lg font-bold text-black-400 dark:text-white">{rider.company.name}</Text>
            </div>

            <Text className="text-xs text-gray-500 dark:text-gray-600">
              {`${t('athlete_affiliated_until', { date: format(parseISO(rider.affiliationDue), 'dd/MM/yyyy') })}`}
            </Text>
          </section>
        </div>
      ) : (
        <Text>{t('loading')}</Text>
      )}
    </ModalDefault>
  );
};

export const ModalPublicRiderDetails = forwardRef(ModalPublicRiderDetailsComponent);
