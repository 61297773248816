import { lazily } from 'react-lazily';

import { IEquitesRouteObject } from '@shared/routes';

const { BalancesCategories } = lazily(() => import('@modules/balances/pages/BalancesCategories'));
const { BalancesDashboard } = lazily(() => import('@modules/balances/pages/BalancesDashboard'));
const { BalancesMovements } = lazily(() => import('@modules/balances/pages/BalancesMovements'));
const { BalancesReports } = lazily(() => import('@modules/balances/pages/BalancesReports'));
const { BalancesWallets } = lazily(() => import('@modules/balances/pages/BalancesWallets'));

const { CompaniesCreate } = lazily(() => import('@modules/companies/pages/CompaniesCreate'));
const { CompaniesEdit } = lazily(() => import('@modules/companies/pages/CompaniesEdit'));
const { CompaniesList } = lazily(() => import('@modules/companies/pages/CompaniesList'));
const { CompaniesProfile } = lazily(() => import('@modules/companies/pages/CompaniesProfile'));

const { Configurations } = lazily(() => import('@modules/configurations/pages/Configurations'));
const { ConfigurationsGeneral } = lazily(() => import('@modules/configurations/pages/ConfigurationsGeneral'));
const { ConfigurationsGroupPermissionsCreate } = lazily(
  () => import('@modules/configurations/pages/ConfigurationsGroupPermissionsCreate'),
);
const { ConfigurationsGroupPermissionsUpdate } = lazily(
  () => import('@modules/configurations/pages/ConfigurationsGroupPermissionsUpdate'),
);
const { ConfigurationsModalities } = lazily(() => import('@modules/configurations/pages/ConfigurationsModalities'));
const { ConfigurationsMyTeam } = lazily(() => import('@modules/configurations/pages/ConfigurationsMyTeam'));

const { ContactsEdit } = lazily(() => import('@modules/contacts/pages/ContactsEdit'));
const { ContactsList } = lazily(() => import('@modules/contacts/pages/ContactsList'));
const { ContactsProfile } = lazily(() => import('@modules/contacts/pages/ContactsProfile'));

const { Dashboard } = lazily(() => import('@modules/dashboard/pages/Dashboard'));

const { HorsesEdit } = lazily(() => import('@modules/horses/pages/HorsesEdit'));
const { HorsesList } = lazily(() => import('@modules/horses/pages/HorsesList'));

const { NotificationsList } = lazily(() => import('@modules/notifications/pages/NotificationsList'));

const { Profile } = lazily(() => import('@modules/profiles/pages/Profile'));
const { ProfileEdit } = lazily(() => import('@modules/profiles/pages/ProfileEdit'));

const { RidersEdit } = lazily(() => import('@modules/riders/pages/RidersEdit'));
const { RidersList } = lazily(() => import('@modules/riders/pages/RidersList'));

export class EquitesRoutes {
  public static privateRoutes: IEquitesRouteObject[] = [
    // App dashboard
    { path: '/', element: <Dashboard />, title: 'dashboard' },

    // Balances
    { path: '/balances/categories', element: <BalancesCategories />, title: 'balances_categories' },
    { path: '/balances/dashboard', element: <BalancesDashboard />, title: 'balances_dashboard' },
    { path: '/balances/movements', element: <BalancesMovements />, title: 'balances_movements' },
    { path: '/balances/reports', element: <BalancesReports />, title: 'balances_reports' },
    { path: '/balances/wallets', element: <BalancesWallets />, title: 'balances_wallets' },

    // Companies
    { path: '/companies', element: <CompaniesList />, title: 'companies_list' },
    { path: '/companies/:companyId/edit', element: <CompaniesEdit />, title: 'companies_edit' },
    { path: '/companies/:companyId/profile', element: <CompaniesProfile />, title: 'companies_profile' },
    { path: '/companies/new', element: <CompaniesCreate />, title: 'companies_create' },

    // Configurations
    {
      path: '/configurations',
      element: <Configurations />,
      title: 'configurations',
    },
    {
      path: '/configurations/general',
      element: <ConfigurationsGeneral />,
      title: 'configurations_general',
    },
    {
      path: '/configurations/my-team',
      element: <ConfigurationsMyTeam />,
      title: 'configurations_my_team',
    },
    {
      path: '/configurations/modalities-and-categories',
      element: <ConfigurationsModalities />,
      title: 'configurations_modalities',
    },
    {
      path: '/configurations/my-team/group-permissions/new',
      element: <ConfigurationsGroupPermissionsCreate />,
      title: 'configurations_group_permissions_create',
    },
    {
      path: '/configurations/my-team/group-permissions/:groupPermissionsId/edit',
      element: <ConfigurationsGroupPermissionsUpdate />,
      title: 'configurations_group_permissions_edit',
    },

    // Contacts
    { path: '/contacts', element: <ContactsList />, title: 'contacts_list' },
    { path: '/contacts/:contactId/edit', element: <ContactsEdit />, title: 'contacts_edit' },
    { path: '/contacts/:contactId/profile', element: <ContactsProfile />, title: 'contacts_profile' },

    // Horses
    { path: '/horses', element: <HorsesList />, title: 'horses_list' },
    { path: '/horses/:horseId/edit', element: <HorsesEdit />, title: 'horses_edit' },

    // Notifications
    { path: '/notifications', element: <NotificationsList />, title: 'notifications' },

    // Profile
    { path: '/profile', element: <Profile />, title: 'profile' },
    { path: '/profile/edit', element: <ProfileEdit />, title: 'profile_edit' },

    // Riders
    { path: '/riders', element: <RidersList />, title: 'riders_list' },
    { path: '/riders/:riderId/edit', element: <RidersEdit />, title: 'riders_edit' },
  ];
}
