import { useContext } from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';

import { EquitesFormContext, EquitesFormContextProps } from '.';

export function useEquitesFormContext(): UseFormReturn & EquitesFormContextProps {
  const formContext = useFormContext();
  const equitesFormContext = useContext(EquitesFormContext);

  return { ...formContext, ...equitesFormContext };
}
