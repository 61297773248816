import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Sun, MoonStars } from '@phosphor-icons/react';

import LogoWhite from '@shared/assets/images/logo-white.webp';
import LogoDefault from '@shared/assets/images/logo.webp';
import { Button } from '@shared/components/Button';

import { useTheme } from '@modules/globals/hooks/useTheme';

export const PublicHorseListHeader = (): ReactElement => {
  const { t } = useTranslation('public-pages');

  const { handleChangeTheme, theme } = useTheme();

  return (
    <header className="flex w-full flex-col items-center gap-4 lg:flex-row lg:gap-0">
      <Link to="https://equites.com.br" target="_blank" rel="noreferrer noopener">
        <img src={theme === 'dark' ? LogoWhite : LogoDefault} alt="Equites Logo" className="w-40" />
      </Link>

      <section className="flex w-full flex-1 flex-col-reverse items-center justify-end gap-2 lg:flex-row lg:gap-4">
        <button type="button" aria-label="toggle-dark-mode" onClick={handleChangeTheme}>
          {theme === 'dark' ? (
            <Sun size={20} className="text-yellow-500" />
          ) : (
            <MoonStars size={20} className="text-black-400" weight="duotone" />
          )}
        </button>

        <Button variant="secondary" className="text-center lg:w-fit" asChild>
          <Link to="https://bemvindo.equites.com.br" target="_blank" rel="noreferrer noopener">
            {t('already_has_account')}
          </Link>
        </Button>

        <Button className="text-center lg:w-fit" asChild>
          <Link to="https://bemvindo.equites.com.br/sign-up" target="_blank" rel="noreferrer noopener">
            {t('register_yourself')}
          </Link>
        </Button>
      </section>
    </header>
  );
};
