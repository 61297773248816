import { createContext, FC, PropsWithChildren, useMemo, useState } from 'react';

import { IInputNumberContext } from '../types';

const InputNumberContext = createContext({} as IInputNumberContext);

const InputNumberProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isActive, setIsActive] = useState(false);

  const contextValue = useMemo<IInputNumberContext>(
    () => ({ isActive, activateInput: () => setIsActive(true), deactivateInput: () => setIsActive(false) }),
    [isActive],
  );

  return <InputNumberContext.Provider value={contextValue}>{children}</InputNumberContext.Provider>;
};

export { InputNumberContext, InputNumberProvider };
