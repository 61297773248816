import { ReactElement, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Eye } from '@phosphor-icons/react';

import { IPageSelected } from '@shared/components/Paginate/types';
import { Table } from '@shared/components/Table';
import { Text } from '@shared/components/Text';
import { Tooltip } from '@shared/components/Tooltip';

import { useFetchAssociationsRidersPublic } from '@modules/riders/hooks/useFetchAssociationsRidersPublic';
import { AssociationRiderByCompanySlug } from '@modules/riders/types/AssociationRidersPublic/public';

import { IModalPublicRiderDetailsHandles, ModalPublicRiderDetails } from '../ModalPublicRiderDetails';
import { PublicRiderListEmptyState } from '../PublicRiderListEmptyState';

export const PublicRiderListItems = (): ReactElement => {
  const modalPublicRiderDetailsRef = useRef<IModalPublicRiderDetailsHandles>(null);

  const { t } = useTranslation('public-pages');

  const [, setUrlSearchParams] = useSearchParams();

  const { data: riders } = useFetchAssociationsRidersPublic();

  const [riderSelected, setRiderSelected] = useState({} as AssociationRiderByCompanySlug);

  const openModalPublicRiderDetails = (rider: AssociationRiderByCompanySlug): void => {
    setRiderSelected(rider);
    modalPublicRiderDetailsRef.current?.open();
  };

  const handlePageChange = (data: IPageSelected): void => {
    setUrlSearchParams(state => {
      state.set('page', (data.selected + 1).toString());
      return state;
    });
  };

  return (
    <>
      <section className="mt-10 flex w-full justify-center">
        {riders?.totalItems ? (
          <Table.Root>
            <Table.Table>
              <Table.Head>
                <Table.HeadRow>
                  <th aria-label="id">
                    <Text className="font-semibold text-green-500">
                      {`ID ${riders.items[0].registrationNumber.split('-')[0]}`}
                    </Text>
                  </th>

                  <th aria-label="name">
                    <Text className="font-semibold text-green-500">{t('name')}</Text>
                  </th>

                  <th aria-label="categories">
                    <Text className="font-semibold text-green-500">{t('categories')}</Text>
                  </th>

                  <th aria-label="entity">
                    <Text className="font-semibold text-green-500">{t('entity')}</Text>
                  </th>

                  <th aria-label="actions">{''}</th>
                </Table.HeadRow>
              </Table.Head>
              <Table.Body>
                {riders.items.map(rider => (
                  <Table.BodyRow key={rider.registrationNumber}>
                    <td aria-label="id">
                      <Text className="text-black-400 dark:text-white">{rider.registrationNumber.split('-')[1]}</Text>
                    </td>

                    <td aria-label="name">
                      <Text className="text-black-400 dark:text-white">{rider.name}</Text>
                    </td>

                    <td aria-label="categories">
                      <Text className="text-black-400 dark:text-white">
                        {rider.categories.length
                          ? rider.categories.map(category => category.slug || category.name).join(', ')
                          : '-'}
                      </Text>
                    </td>

                    <td aria-label="entity">
                      <Text className="text-black-400 dark:text-white">{rider.company.slug}</Text>
                    </td>

                    <td aria-label="actions">
                      <Tooltip.Root>
                        <Tooltip.Trigger>
                          <Eye
                            size={16}
                            className="cursor-pointer text-gray-500 dark:text-gray-600"
                            onClick={() => openModalPublicRiderDetails(rider)}
                          />
                        </Tooltip.Trigger>
                        <Tooltip.Content text={t('view_details')} />
                      </Tooltip.Root>
                    </td>
                  </Table.BodyRow>
                ))}
              </Table.Body>
            </Table.Table>
            <Table.TotalItems totalItems={riders.totalItems || 0} />
            <Table.Pagination onPageChange={handlePageChange} pageCount={riders.qtPages || 0} />
          </Table.Root>
        ) : (
          <PublicRiderListEmptyState />
        )}
      </section>

      {/* Modals */}
      <ModalPublicRiderDetails ref={modalPublicRiderDetailsRef} rider={riderSelected} />
    </>
  );
};
