import { FC, ReactElement } from 'react';

import { Image, X } from '@phosphor-icons/react';

import { Avatar } from '../Avatar';
import { Text } from '../Text';
import { chipVariants } from './styles';
import { IChipProps } from './types';

export const Chip: FC<IChipProps> = (props): ReactElement => {
  const { allWidth = false, color, icon = Image, imageUrl, text, onClick } = props;

  const { text: textClassName, wrapper, x } = chipVariants({ allWidth, color, withImage: Boolean(imageUrl) });

  return (
    <div className={wrapper()}>
      {imageUrl && (
        <Avatar.Root mode="ROUNDED" size="xxs">
          <Avatar.Content icon={icon ?? Image} url={imageUrl} />
        </Avatar.Root>
      )}

      <Text className={textClassName()}>{text}</Text>

      {onClick && (
        <button aria-label="btn-close" type="button" className="flex items-center justify-center" onClick={onClick}>
          <X size={imageUrl ? 16 : 14} className={x()} weight="bold" />
        </button>
      )}
    </div>
  );
};
