import { FC, memo, PropsWithChildren, ReactElement, useCallback, useEffect } from 'react';
import { isDesktop } from 'react-device-detect';
import { useUpdateEffect } from 'react-use';

import { AnimatePresence, motion } from 'framer-motion';

import { useApp } from '@modules/globals/hooks/useApp';

import { IModalDefaultProps } from './types';

const ModalDefaultComponent: FC<PropsWithChildren<IModalDefaultProps>> = (props): ReactElement => {
  const { children, close, visible } = props;

  const { enablePtr, disablePtr } = useApp();

  const handleEscapeKey = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        close();
      }
    },
    [close],
  );

  useEffect(() => {
    // Remove scroll on background when sidebar is opened
    if (visible) {
      if (!isDesktop) {
        disablePtr();
      }

      document.body.style.height = '100vh';
      document.body.style.overflow = 'hidden';
    } else {
      if (!isDesktop) {
        enablePtr();
      }

      document.body.style.removeProperty('height');
      document.body.style.removeProperty('overflow');
    }
  }, [disablePtr, enablePtr, visible]);

  useUpdateEffect(() => {
    if (visible) {
      document.addEventListener('keydown', handleEscapeKey, false);
    } else {
      document.removeEventListener('keydown', handleEscapeKey, false);
    }
  }, [visible]);

  return (
    <AnimatePresence mode="wait">
      {visible && (
        // Backdrop
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ type: 'tween', when: 'beforeChildren' }}
          className="fixed left-0 top-0 z-20 flex h-full w-full items-center justify-center bg-black-400/90"
        >
          {/* Content */}
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 100 }}
            transition={{ type: 'spring', stiffness: 250, duration: 0.3 }}
            className="z-20 w-11/12 overflow-visible rounded-xl bg-background-light p-4 dark:bg-black-300 lg:w-auto lg:p-8"
          >
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export const ModalDefault = memo(ModalDefaultComponent);
