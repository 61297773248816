import { AxiosInstance } from 'axios';
import fileDownload from 'js-file-download';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';
import { openPdf } from '@shared/utils/OpenPDF';

import { IRiderPayment } from '@modules/riders/types/AssociationRidersPayments/payments';
import {
  ICreateRiderPaymentRequest,
  IDeleteRiderPaymentRequest,
  IFindRidersPaymentsRequest,
  IUpdateRiderPaymentRequest,
} from '@modules/riders/types/AssociationRidersPayments/requests';

export class AssociationRidersPayments {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Rider payments ----------

  async createPayment(data: ICreateRiderPaymentRequest): Promise<IRiderPayment> {
    const { riderId, ...body } = data;
    const response = await this.instance.post(`/associations/riders/${riderId}/payments`, body);

    return response.data;
  }

  async deletePayment(data: IDeleteRiderPaymentRequest): Promise<void> {
    const { riderId, riderPaymentId } = data;
    const response = await this.instance.delete(`/associations/riders/${riderId}/payments/${riderPaymentId}`);

    return response.data;
  }

  async find(dto: IFindRidersPaymentsRequest): Promise<IPaginateDTO<IRiderPayment>> {
    const { riderId, ...params } = dto;

    const response = await this.instance.get(`/associations/riders/${riderId}/payments`, { params });

    return response.data;
  }

  async updatePayment(data: IUpdateRiderPaymentRequest): Promise<IRiderPayment> {
    const { riderId, riderPaymentId, ...body } = data;
    const response = await this.instance.patch(`/associations/riders/${riderId}/payments/${riderPaymentId}`, body);

    return response.data;
  }

  // ---------- Export rider payments ----------

  async exportPaymentsCsv(request: IFindRidersPaymentsRequest): Promise<void> {
    const { riderId, ...params } = request;

    const url = `/associations/riders/${riderId}/reports/payments/csv/export`;
    const { data } = await this.instance.get(url, { params });

    fileDownload(data, `rider-payments-${params.from}-to-${params.to}.csv`);
  }

  async exportPaymentsPdf(request: IFindRidersPaymentsRequest): Promise<void> {
    const { riderId, ...params } = request;

    const url = `/associations/riders/${riderId}/reports/payments/pdf/export`;
    const { data } = await this.instance.get(url, { params, responseType: 'blob' });

    openPdf(data);
  }
}

export const associationRidersPayments = (instance: AxiosInstance): AssociationRidersPayments =>
  new AssociationRidersPayments(instance);
