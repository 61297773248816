import { FC, ReactElement, cloneElement } from 'react';

import { User } from '@phosphor-icons/react';
import * as AvatarPrimitive from '@radix-ui/react-avatar';

import { avatarVariants } from './styles';
import { IAvatarContentProps, IAvatarProps } from './types';

// ------------------------------------------

const AvatarContent: FC<IAvatarContentProps> = (props): ReactElement => {
  const { altImg, classNameImg, icon: Icon = User, iconSize, mode, size, url } = props;

  const { image } = avatarVariants({ mode, size });

  const getIconSize = (): number => {
    switch (size) {
      case '3xs':
        return iconSize ?? 12;
      case 'xxs':
        return iconSize ?? 16;
      case 'xs':
        return iconSize ?? 24;
      case 'sm':
        return iconSize ?? 34;
      case 'md':
        return iconSize ?? 54;
      case 'lg':
        return iconSize ?? 74;
      case 'xl':
        return iconSize ?? 94;
      case 'full':
        return iconSize ?? 94;
      default:
        return 74;
    }
  };

  return (
    <>
      <AvatarPrimitive.Image
        src={url ?? ''}
        alt={altImg ?? 'avatar-image'}
        className={image({ className: classNameImg })}
      />

      <AvatarPrimitive.Fallback delayMs={600}>
        <Icon size={getIconSize()} className="text-gray-500 dark:text-gray-400" />
      </AvatarPrimitive.Fallback>
    </>
  );
};

AvatarContent.displayName = 'Avatar.Content';

// ------------------------------------------

const AvatarRoot: FC<IAvatarProps> = (props): ReactElement => {
  const { children, mode = 'SQUARE', size, classNameContainer, classNameRoot, onClick } = props;

  const { container } = avatarVariants({ mode, size });

  return (
    <AvatarPrimitive.Root className={classNameRoot}>
      {onClick ? (
        <button type="button" onClick={onClick} className={container({ className: classNameContainer })}>
          {cloneElement(children, { mode, size })}
        </button>
      ) : (
        <div className={container({ className: classNameContainer })}>{cloneElement(children, { mode, size })}</div>
      )}
    </AvatarPrimitive.Root>
  );
};

AvatarRoot.displayName = 'Avatar.Root';

// ------------------------------------------

export const Avatar = {
  Root: AvatarRoot,
  Content: AvatarContent,
};
