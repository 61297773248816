import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Text } from '@shared/components/Text';

export const PublicHorseListFooter = (): ReactElement => {
  const { t } = useTranslation('public-pages');

  return (
    <footer className="mt-10 flex flex-col items-center gap-1 lg:mt-auto">
      <div className="flex items-center gap-1">
        <Text className="text-sm text-gray-500 dark:text-gray-600">{t('developed_with_heart_by')}</Text>
        <Link to="https://equites.com.br" target="_blank" rel="noreferrer noopener">
          <Text className="text-sm text-gray-500 transition-colors hover:text-green-500 dark:text-gray-600">
            {t('equites_team')}
          </Text>
        </Link>
      </div>

      <Text className="text-sm text-gray-500 dark:text-gray-600">{t('copyright_sentence')}</Text>
    </footer>
  );
};
