import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Paginate } from '../Paginate';
import { Text } from '../Text';
import {
  ITableRootProps,
  ITableTableProps,
  ITableHeadProps,
  ITableHeadRowProps,
  ITableBodyProps,
  ITableBodyRowProps,
  ITablePaginationProps,
  ITableTotalItemsProps,
} from './types';

// ------------------------------------------

const TableRoot = ({ children }: ITableRootProps): ReactElement => {
  return <div className="overflow-x-scroll pb-2 scrollbar lg:overflow-x-auto">{children}</div>;
};

TableRoot.displayName = 'Table.Root';

// ------------------------------------------

const TableTable = ({ children }: ITableTableProps): ReactElement => {
  return <table className="w-full min-w-[920px]">{children}</table>;
};

TableTable.displayName = 'Table.Table';

// ------------------------------------------

const TableHead = ({ children }: ITableHeadProps): ReactElement => {
  return <thead>{children}</thead>;
};

TableHead.displayName = 'Table.Head';

// ------------------------------------------

const TableHeadRow = ({ children }: ITableHeadRowProps): ReactElement => {
  return <tr className="h-10 text-left">{children}</tr>;
};

TableHeadRow.displayName = 'Table.HeadRow';

// ------------------------------------------

const TableBody = ({ children }: ITableBodyProps): ReactElement => {
  return <tbody>{children}</tbody>;
};

TableBody.displayName = 'Table.Body';

// ------------------------------------------

const TableBodyRow = ({ children }: ITableBodyRowProps): ReactElement => {
  return (
    <tr className="h-10 border-b-[1px] border-b-black-400/10 transition-colors hover:bg-black-400/5 dark:border-b-gray-500/20 dark:hover:bg-black-500/80">
      {children}
    </tr>
  );
};

TableBodyRow.displayName = 'Table.BodyRow';

// ------------------------------------------

const TableTotalItems = ({ totalItems }: ITableTotalItemsProps): ReactElement => {
  const { t } = useTranslation('globals');

  return (
    <div className="mt-4 flex items-center justify-center gap-1">
      <Text className="text-xs text-gray-500">{totalItems}</Text>
      <Text className="text-xs text-gray-500">{t('registers').toLowerCase()}</Text>
    </div>
  );
};

TableTotalItems.displayName = 'Table.TotalItems';

// ------------------------------------------

const TablePagination = (props: ITablePaginationProps): ReactElement => {
  return (
    <Paginate
      {...props}
      containerClassName="flex items-center justify-center mt-4 gap-4 text-sm text-black-400 dark:text-white antialiased leading-none font-sans"
      activeClassName="text-green-500 font-semibold"
    />
  );
};

TablePagination.displayName = 'Table.Pagination';

// ------------------------------------------

export const Table = {
  Root: TableRoot,
  Table: TableTable,
  Head: TableHead,
  HeadRow: TableHeadRow,
  Body: TableBody,
  BodyRow: TableBodyRow,
  Pagination: TablePagination,
  TotalItems: TableTotalItems,
};
