import { AxiosInstance } from 'axios';

import {
  ICreateAssociationHorseStampRequest,
  IDeleteAssociationHorseStampRequest,
  IFindAssociationHorseStampsRequest,
  IUpdateAssociationHorseStampDTO,
} from '@modules/horses/types/AssociationHorsesStamps/requests';
import { IAssociationHorseStamp } from '@modules/horses/types/AssociationHorsesStamps/stamps';

export class AssociationHorsesStamps {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Horse stamps ----------

  async create(data: ICreateAssociationHorseStampRequest): Promise<IAssociationHorseStamp> {
    const { horseId, ...body } = data;

    const response = await this.instance.post(`/associations/horses/${horseId}/stamps`, body);

    return response.data;
  }

  async delete(data: IDeleteAssociationHorseStampRequest): Promise<void> {
    const { horseId, stampId } = data;

    await this.instance.delete(`/associations/horses/${horseId}/stamps/${stampId}`);
  }

  async findByHorse(data: IFindAssociationHorseStampsRequest): Promise<IAssociationHorseStamp[]> {
    const { horseId } = data;

    const response = await this.instance.get(`/associations/horses/${horseId}/stamps`);

    return response.data;
  }

  async update(data: IUpdateAssociationHorseStampDTO): Promise<IAssociationHorseStamp> {
    const { horseId, stampId, ...body } = data;

    const response = await this.instance.put(`/associations/horses/${horseId}/stamps/${stampId}`, body);

    return response.data;
  }
}

export const associationHorsesStamps = (instance: AxiosInstance): AssociationHorsesStamps =>
  new AssociationHorsesStamps(instance);
