import { ReactElement } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { MagnifyingGlass } from '@phosphor-icons/react';

import { Button } from '@shared/components/Button';
import { InputForm, SelectForm } from '@shared/components/Form/HookForm';
import { ISelectOption } from '@shared/components/Select/types';

import { useFetchAssociationCompaniesPublic } from '@modules/companies/hooks/useFetchAssociationCompaniesPublic';

import {
  ISubmitFilterAssociationRidersPublicSchema,
  SubmitFilterAssociationRidersPublicSchema,
} from '@modules/riders/validations/SubmitFilterAssociationRidersPublicValidation';

export const PublicRiderListFilter = (): ReactElement => {
  const { t } = useTranslation('public-pages');

  const [, setUrlSearchParams] = useSearchParams();

  const form = useForm<ISubmitFilterAssociationRidersPublicSchema>({
    resolver: zodResolver(SubmitFilterAssociationRidersPublicSchema),
  });

  const { data: companies } = useFetchAssociationCompaniesPublic();

  const companiesOptions: ISelectOption[] = companies?.length
    ? companies.map(company => ({ label: company.name, value: company.id, imageUrl: company.logoUrl }))
    : [];

  const handleSubmit = (data: ISubmitFilterAssociationRidersPublicSchema): void => {
    setUrlSearchParams(state => {
      if (data.search) {
        state.set('search', data.search);
      } else {
        state.delete('search');
      }

      if (data.companyId) {
        state.set('companyId', data.companyId);
      } else {
        state.delete('companyId');
      }

      return state;
    });
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        className="mt-6 flex w-full flex-col items-center justify-center gap-2 self-center lg:mt-10 lg:max-w-[920px] lg:flex-row lg:gap-4"
      >
        <InputForm name="search" placeholder={t('search_by_athlete_name')} classNameRoot="w-full" />

        <SelectForm
          name="companyId"
          placeholder={t('select_an_entity')}
          options={companiesOptions}
          withAvatar
          withFilter
          clearable
        />

        <Button variant="tertiary" type="submit" className="flex items-center justify-center gap-3 px-10 lg:w-fit">
          <MagnifyingGlass size={16} className="text-green-500" />
          {t('search')}
        </Button>
      </form>
    </FormProvider>
  );
};
