import { FC, ReactElement } from 'react';

import { twMerge } from 'tailwind-merge';

import { Divider } from '../Divider';
import { Text } from '../Text';
import { IInputLabelProps } from './types';

export const InputLabel: FC<IInputLabelProps> = ({ children, classNameRoot, required = false }): ReactElement => {
  return (
    <div className={twMerge('mb-2 flex flex-col', classNameRoot)}>
      <div className="flex gap-1">
        <Text className="text-md font-semibold leading-4 text-black-400 dark:text-white">{children}</Text>

        {required && <Text className="text-red-500">*</Text>}
      </div>

      <Divider color="red" mode="short" className="mt-2" />
    </div>
  );
};
