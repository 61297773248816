import { FC, MouseEvent, ReactElement, useEffect, useRef, useState } from 'react';

import { Eye, EyeClosed } from '@phosphor-icons/react';
import { Slot } from '@radix-ui/react-slot';
import { twJoin } from 'tailwind-merge';

import { Text } from '../Text';
import { InputPasswordProvider } from './contexts/InputPasswordContext';
import { useInputPassword } from './hooks/useInputPassword';
import {
  IInputPasswordHelpTextProps,
  IInputPasswordInputRootProps,
  IInputPasswordRootProps,
  InputPasswordIconProps,
  InputPasswordInputProps,
} from './types';

// ------------------------------------------

/**
 * InputPassword Root - Wrapper to all input
 */
const InputPasswordRoot: FC<IInputPasswordRootProps> = (props): ReactElement => {
  const { children, className } = props;

  return (
    <InputPasswordProvider>
      <div className={twJoin('flex flex-col', className)}>{children}</div>
    </InputPasswordProvider>
  );
};

InputPasswordRoot.displayName = 'InputPassword.Root';

// ------------------------------------------

/**
 * InputPassword Input Root - Wrapper to input field, to use with icon or not
 */
const InputPasswordInputRoot: FC<IInputPasswordInputRootProps> = (props): ReactElement => {
  const { children, hasError = false, className } = props;

  const contentRef = useRef<HTMLDivElement>(null);

  const { isActive, deactivateInput } = useInputPassword();

  useEffect(() => {
    const handleClickOutside = (event: globalThis.MouseEvent): void => {
      if (!contentRef.current?.contains(event.target as Node)) {
        deactivateInput();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [deactivateInput]);

  return (
    <div
      ref={contentRef}
      data-is-active={isActive}
      data-has-error={hasError}
      className={twJoin(
        'flex h-11 w-full items-center rounded-lg border border-gray-200 px-4 transition-all data-[has-error=true]:border-red-500 data-[is-active=true]:border-green-500 dark:border-black-400 lg:h-10',
        className,
      )}
    >
      {children}
    </div>
  );
};

InputPasswordInputRoot.displayName = 'InputPassword.InputRoot';

// ------------------------------------------

/**
 * InputPassword Icon - Icon to show before input field
 */
const InputPasswordIcon: FC<InputPasswordIconProps> = ({ children }): ReactElement => {
  return <Slot className="mr-1 h-5 w-5 text-gray-400">{children}</Slot>;
};

InputPasswordIcon.displayName = 'InputPassword.Icon';

// ------------------------------------------

/**
 * InputPassword HelpText - Text to show under input field with help or error messages
 */
const InputPasswordHelpText: IInputPasswordHelpTextProps = ({ children, className }): ReactElement => {
  return <Text className={twJoin('mt-1 block text-xs text-gray-400', className)}>{children}</Text>;
};

InputPasswordHelpText.displayName = 'InputPassword.HelpText';

// ------------------------------------------

/**
 * InputPassword Input - Input field
 */
const InputPasswordInput: FC<InputPasswordInputProps> = ({ className, inputRef, onClick, ...props }): ReactElement => {
  const [inputType, setInputType] = useState<'password' | 'text'>('password');

  const { activateInput } = useInputPassword();

  const Icon = inputType === 'password' ? Eye : EyeClosed;

  const handleIconClick = (): void => {
    setInputType(inputType === 'password' ? 'text' : 'password');
  };

  const handleInputClick = (event: MouseEvent<HTMLInputElement>): void => {
    if (onClick) {
      onClick(event);
    }

    activateInput();
  };

  return (
    <>
      <input
        ref={inputRef}
        type={inputType}
        onClick={handleInputClick}
        className={twJoin(
          'w-full flex-1 bg-transparent text-sm text-black-400 caret-green-500 outline-none placeholder:text-sm placeholder:text-gray-400 disabled:cursor-not-allowed disabled:text-gray-500 disabled:placeholder:text-gray-200 dark:text-white dark:placeholder:text-gray-500 dark:disabled:text-gray-700 dark:disabled:placeholder:text-black-400',
          className,
        )}
        {...props}
      />

      <Icon size={16} onClick={handleIconClick} className="cursor-pointer text-gray-500" />
    </>
  );
};

InputPasswordInput.displayName = 'InputPassword.Input';

// ------------------------------------------

export const InputPassword = {
  Root: InputPasswordRoot,
  Input: InputPasswordInput,
  InputRoot: InputPasswordInputRoot,
  Icon: InputPasswordIcon,
  HelpText: InputPasswordHelpText,
};
