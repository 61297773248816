import { AxiosInstance } from 'axios';

import { IModalityCategory } from '@modules/modalities/types/ModalitiesCategories/modalitiesCategories';
import {
  ICreateModalityCategoryRequest,
  IDeleteModalityCategoryByIdRequest,
  IFindCategoriesByModalityIdRequest,
  IUpdateModalityCategoryRequest,
} from '@modules/modalities/types/ModalitiesCategories/requests';

export class ModalitiesCategories {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async create(data: ICreateModalityCategoryRequest): Promise<IModalityCategory> {
    const { modalityId, ...body } = data;

    const response = await this.instance.post(`/associations/modalities/${modalityId}/categories`, body);

    return response.data;
  }

  async delete(data: IDeleteModalityCategoryByIdRequest): Promise<void> {
    const { categoryId, modalityId } = data;

    await this.instance.delete(`/associations/modalities/${modalityId}/categories/${categoryId}`);
  }

  async findByModalityId(data: IFindCategoriesByModalityIdRequest): Promise<IModalityCategory[]> {
    const { modalityId } = data;

    const response = await this.instance.get(`/associations/modalities/${modalityId}/categories`);

    return response.data;
  }

  async update(data: IUpdateModalityCategoryRequest): Promise<IModalityCategory> {
    const { categoryId, modalityId, ...body } = data;

    const response = await this.instance.patch(`/associations/modalities/${modalityId}/categories/${categoryId}`, body);

    return response.data;
  }
}

export const modalitiesCategories = (instance: AxiosInstance): ModalitiesCategories =>
  new ModalitiesCategories(instance);
