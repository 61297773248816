/**
 * ConfigurationRoles
 * @readonly
 * @enum {string}
 */
export enum ConfigurationRoles {
  /** Get configuration from company */
  GET_CONFIGURATIONS = 'GET_CONFIGURATIONS',

  /** Update image to show in reports */
  UPDATE_CONFIGURATION_IMAGE_REPORTS = 'UPDATE_CONFIGURATION_IMAGE_REPORTS',

  /** Update general configurations from company */
  UPDATE_CONFIGURATIONS = 'UPDATE_CONFIGURATIONS',
}
