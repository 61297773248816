import { ReactElement } from 'react';

import { Lock } from '@phosphor-icons/react';

export const LockComponent = (): ReactElement => {
  return (
    <div className="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center rounded-lg bg-gray-200/70 dark:bg-black-400/80">
      <Lock size={84} className="text-black-500/50 dark:text-gray-500" />
    </div>
  );
};
