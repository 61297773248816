import {
  AssociationHorsesAffiliations,
  associationHorsesAffiliations,
} from '@modules/affiliations/services/HorsesAffiliations';
import {
  AssociationRidersAffiliations,
  associationRidersAffiliations,
} from '@modules/affiliations/services/RidersAffiliations';
import { Persons, persons } from '@modules/persons/services/Persons';
import { AxiosInstance } from 'axios';

import { IClientConfigProps } from '@shared/clients/types/types';

import { addresses, Addresses } from '@modules/addresses/services/Addresses';

import { balances, Balances } from '@modules/balances/services/Balances';
import { BalancesCategories, balancesCategories } from '@modules/balances/services/Categories';
import { balancesDashboard, BalancesDashboard } from '@modules/balances/services/Dashboard';
import { balancesReports, BalancesReports } from '@modules/balances/services/Reports';
import { balancesWallets, BalancesWallets } from '@modules/balances/services/Wallets';

import { associationCompanies, AssociationCompanies } from '@modules/companies/services/Companies';
import { AssociationCompaniesPublic, associationCompaniesPublic } from '@modules/companies/services/CompaniesPublic';

import { configuration, Configuration } from '@modules/configurations/services/Configurations';
import { myTeam, MyTeam } from '@modules/configurations/services/MyTeam';

import { AssociationDashboard, associationDashboard } from '@modules/dashboard/services/Dashboard';

import { associationHorses, AssociationsHorses } from '@modules/horses/services/AssociationsHorses';
import {
  associationHorsesIdentifications,
  AssociationHorsesIdentifications,
} from '@modules/horses/services/AssociationsHorsesIdentifications';
import {
  AssociationHorsesPayments,
  associationHorsesPayments,
} from '@modules/horses/services/AssociationsHorsesPayments';
import { associationHorsesPublic, AssociationsHorsesPublic } from '@modules/horses/services/AssociationsHorsesPublic';
import {
  associationHorsesReports,
  AssociationsHorsesReports,
} from '@modules/horses/services/AssociationsHorsesReports';
import { associationHorsesStamps, AssociationHorsesStamps } from '@modules/horses/services/AssociationsHorsesStamps';

import { modalities, Modalities } from '@modules/modalities/services/Modalities';
import { modalitiesCategories, ModalitiesCategories } from '@modules/modalities/services/ModalitiesCategories';

import { ProfileAbout, profileAbout } from '@modules/profiles/services/About';
import { profileCompanyData, ProfileCompanyData } from '@modules/profiles/services/CompanyData';
import { profile, Profile } from '@modules/profiles/services/Profile';

import { associationRiders, AssociationsRiders } from '@modules/riders/services/AssociationRiders';
import {
  associationRidersCategories,
  AssociationsRidersCategories,
} from '@modules/riders/services/AssociationRidersCategories';
import {
  associationRidersPayments,
  AssociationRidersPayments,
} from '@modules/riders/services/AssociationRidersPayments';
import { associationRidersPublic, AssociationsRidersPublic } from '@modules/riders/services/AssociationRidersPublic';
import { associationRidersReports, AssociationsRidersReports } from '@modules/riders/services/AssociationRidersReports';

import { companiesApiConfig } from './config';

export class CompaniesAPI {
  private instance: AxiosInstance;

  constructor(params: IClientConfigProps) {
    this.instance = companiesApiConfig(params).instance();
  }

  addresses(): Addresses {
    return addresses(this.instance);
  }

  associationCompanies(): AssociationCompanies {
    return associationCompanies(this.instance);
  }

  associationCompaniesPublic(): AssociationCompaniesPublic {
    return associationCompaniesPublic(this.instance);
  }

  associationDashboard(): AssociationDashboard {
    return associationDashboard(this.instance);
  }

  associationHorses(): AssociationsHorses {
    return associationHorses(this.instance);
  }

  associationHorsesAffiliations(): AssociationHorsesAffiliations {
    return associationHorsesAffiliations(this.instance);
  }

  associationHorsesIdentifications(): AssociationHorsesIdentifications {
    return associationHorsesIdentifications(this.instance);
  }

  associationHorsesPayments(): AssociationHorsesPayments {
    return associationHorsesPayments(this.instance);
  }

  associationHorsesPublic(): AssociationsHorsesPublic {
    return associationHorsesPublic(this.instance);
  }

  associationHorsesReports(): AssociationsHorsesReports {
    return associationHorsesReports(this.instance);
  }

  associationHorsesStamps(): AssociationHorsesStamps {
    return associationHorsesStamps(this.instance);
  }

  associationPersons(): Persons {
    return persons(this.instance);
  }

  associationRiders(): AssociationsRiders {
    return associationRiders(this.instance);
  }

  associationRidersAffiliations(): AssociationRidersAffiliations {
    return associationRidersAffiliations(this.instance);
  }

  associationRidersCategories(): AssociationsRidersCategories {
    return associationRidersCategories(this.instance);
  }

  associationRidersPayments(): AssociationRidersPayments {
    return associationRidersPayments(this.instance);
  }

  associationRidersPublic(): AssociationsRidersPublic {
    return associationRidersPublic(this.instance);
  }

  associationRidersReports(): AssociationsRidersReports {
    return associationRidersReports(this.instance);
  }

  balances(): Balances {
    return balances(this.instance);
  }

  balancesCategories(): BalancesCategories {
    return balancesCategories(this.instance);
  }

  balancesDashboard(): BalancesDashboard {
    return balancesDashboard(this.instance);
  }

  balancesReports(): BalancesReports {
    return balancesReports(this.instance);
  }

  balancesWallets(): BalancesWallets {
    return balancesWallets(this.instance);
  }

  configurations(): Configuration {
    return configuration(this.instance);
  }

  modalities(): Modalities {
    return modalities(this.instance);
  }

  modalitiesCategories(): ModalitiesCategories {
    return modalitiesCategories(this.instance);
  }

  myTeam(): MyTeam {
    return myTeam(this.instance);
  }

  profiles(): Profile {
    return profile(this.instance);
  }

  profilesAbout(): ProfileAbout {
    return profileAbout(this.instance);
  }

  profilesCompanyData(): ProfileCompanyData {
    return profileCompanyData(this.instance);
  }
}

export const CompaniesFactoryAPI = (params: IClientConfigProps): CompaniesAPI => new CompaniesAPI(params);
