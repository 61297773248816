import { AxiosInstance } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import { AssociationRiderByCompanySlug } from '@modules/riders/types/AssociationRidersPublic/public';
import { FindAssociationRidersByCompanySlugRequest } from '@modules/riders/types/AssociationRidersPublic/requests';

type FindResponse = Promise<IPaginateDTO<AssociationRiderByCompanySlug>>;

export class AssociationsRidersPublic {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Riders ----------

  public async find(data: FindAssociationRidersByCompanySlugRequest): FindResponse {
    const { slug, ...params } = data;

    const response = await this.instance.get(`/public/associations/${slug}/riders`, { params });

    return response.data;
  }
}

export const associationRidersPublic = (instance: AxiosInstance): AssociationsRidersPublic =>
  new AssociationsRidersPublic(instance);
