/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useParams } from 'react-router-dom';

import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';

import { AssociationCompanySummaryPublic } from '../types/CompaniesPublic/public';

type RouteParams = {
  slug: string;
};

export function useFetchAssociationCompaniesPublic() {
  const { slug = '' } = useParams<keyof RouteParams>();

  return useQuery<AssociationCompanySummaryPublic[]>({
    enabled: Boolean(slug),
    queryKey: ['public:association-companies:list', { slug }],
    queryFn: () => CompaniesClient.associationCompaniesPublic().findAll({ slug }),
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 30, // 30 minutes
  });
}
