import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { SpinnerGap } from '@phosphor-icons/react';

import { Text } from '@shared/components/Text';

export const InputLoader = (): ReactElement => {
  const { t } = useTranslation('globals');

  return (
    <div className="flex flex-1 items-center gap-1">
      <SpinnerGap size={16} className="animate-spin text-gray-500 dark:text-gray-600" />
      <Text className="text-xs text-gray-500 dark:text-gray-600">{t('loading')}</Text>
    </div>
  );
};
