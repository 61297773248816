import { AxiosInstance } from 'axios';

import { AssociationCompanySummaryPublic } from '@modules/companies/types/CompaniesPublic/public';
import { FindAssociationCompaniesBySlugRequest } from '@modules/companies/types/CompaniesPublic/requests';

export class AssociationCompaniesPublic {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Companies ----------

  public async getCurrent({ slug }: FindAssociationCompaniesBySlugRequest): Promise<AssociationCompanySummaryPublic> {
    const response = await this.instance.get(`/public/associations/${slug}/current`);

    return response.data;
  }

  public async findAll({ slug }: FindAssociationCompaniesBySlugRequest): Promise<AssociationCompanySummaryPublic[]> {
    const response = await this.instance.get(`/public/associations/${slug}/companies`);

    return response.data;
  }
}

export const associationCompaniesPublic = (instance: AxiosInstance): AssociationCompaniesPublic =>
  new AssociationCompaniesPublic(instance);
