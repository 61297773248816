import { AxiosInstance } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import { IAssociationHorseSummary } from '@modules/horses/types/AssociationHorses/horses';

import {
  CreateAssociationRiderRequest,
  DeleteAssociationRiderRequest,
  FindAssociationRiderAboutRequest,
  FindAssociationRiderHorsesRequest,
  FindAssociationRidersPaginatedRequest,
  UpdateAssociationRiderAboutRequest,
} from '@modules/riders/types/AssociationRiders/requests';
import {
  IAssociationRiderAbout,
  IAssociationRiderSummary,
  ICountAssociationRidersSummary,
} from '@modules/riders/types/AssociationRiders/riders';

type IFindSummaryResponse = Promise<IPaginateDTO<IAssociationRiderSummary>>;

export class AssociationsRiders {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Riders ----------

  public async create(data: CreateAssociationRiderRequest): Promise<IAssociationRiderSummary> {
    const response = await this.instance.post('/associations/riders', data);

    return response.data;
  }

  public async deleteById(data: DeleteAssociationRiderRequest): Promise<void> {
    await this.instance.delete(`/associations/riders/${data.riderId}`);
  }

  public async findAboutById(params: FindAssociationRiderAboutRequest): Promise<IAssociationRiderAbout> {
    const response = await this.instance.get(`/associations/riders/${params.riderId}`);

    return response.data;
  }

  public async findSummary(params: FindAssociationRidersPaginatedRequest): IFindSummaryResponse {
    const response = await this.instance.get('/associations/riders', { params });

    return response.data;
  }

  public async update({ id, ...data }: UpdateAssociationRiderAboutRequest): Promise<IAssociationRiderAbout> {
    const response = await this.instance.put(`/associations/riders/${id}`, data);

    return response.data;
  }

  // ---------- Counter ----------

  public async counterSummary(): Promise<ICountAssociationRidersSummary> {
    const response = await this.instance.get('/associations/riders/counter/total-affiliates-no-affiliates');

    return response.data;
  }

  // ---------- Horses ----------

  public async horses({ riderId }: FindAssociationRiderHorsesRequest): Promise<IAssociationHorseSummary[]> {
    const response = await this.instance.get(`/associations/riders/${riderId}/horses`);

    return response.data;
  }
}

export const associationRiders = (instance: AxiosInstance): AssociationsRiders => new AssociationsRiders(instance);
