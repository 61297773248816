/**
 * AppointmentRoles
 * @readonly
 * @enum {string}
 */
export enum AppointmentRoles {
  /** Create a new appointment. */
  CREATE_APPOINTMENT = 'CREATE_APPOINTMENT',

  /** Delete an appointment. */
  DELETE_APPOINTMENT = 'DELETE_APPOINTMENT',

  /** Export appointments from calendar as PDF. */
  EXPORT_APPOINTMENTS_CALENDAR = 'EXPORT_APPOINTMENTS_CALENDAR',

  /** Export appointments from history as PDF. */
  EXPORT_APPOINTMENTS_HISTORY = 'EXPORT_APPOINTMENTS_HISTORY',

  /** Get appointments. */
  GET_APPOINTMENTS = 'GET_APPOINTMENTS',

  /** Get requests to appointments from athletes. */
  GET_APPOINTMENTS_ATHLETES_REQUESTS = 'GET_APPOINTMENTS_ATHLETES_REQUESTS',

  /** Get appointments history. */
  GET_APPOINTMENTS_HISTORY = 'GET_APPOINTMENTS_HISTORY',

  /** Update an appointment. */
  UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT',

  /** Update appointment request from athlete. */
  UPDATE_APPOINTMENTS_ATHLETES_REQUESTS = 'UPDATE_APPOINTMENTS_ATHLETES_REQUESTS',
}
