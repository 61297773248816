import { IHorseAccountablePerson, IPerson } from '@modules/persons/types/persons';
import {
  IFindAssociationHorsePersonByHorseIdRequest,
  IFindAssociationRiderPersonByRiderIdRequest,
  IUpsertAssociationHorsePersonDTO,
  IUpsertAssociationRiderPersonDTO,
} from '@modules/persons/types/requests';
import { AxiosInstance } from 'axios';

export class Persons {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Accountable ----------

  public async findAccountable(): Promise<IPerson[]> {
    const response = await this.instance.get(`/associations/persons/accountable`);

    return response.data;
  }

  // ---------- Accountable by horse ----------

  public async findAccountableByHorse(
    data: IFindAssociationHorsePersonByHorseIdRequest,
  ): Promise<IHorseAccountablePerson> {
    const { horseId } = data;

    const response = await this.instance.get(`/associations/horses/${horseId}/accountable`);

    return response.data;
  }

  public async updateAccountableByHorse(dto: IUpsertAssociationHorsePersonDTO): Promise<IPerson> {
    const { horseId, ...body } = dto;

    const response = await this.instance.put(`/associations/horses/${horseId}/accountable`, body);

    return response.data;
  }

  // ---------- Accountable by rider ----------

  public async findAccountableByRider(data: IFindAssociationRiderPersonByRiderIdRequest): Promise<IPerson> {
    const { riderId } = data;

    const response = await this.instance.get(`/associations/riders/${riderId}/accountable`);

    return response.data;
  }

  public async updateAccountableByRider(dto: IUpsertAssociationRiderPersonDTO): Promise<IPerson> {
    const { riderId, ...body } = dto;

    const response = await this.instance.put(`/associations/riders/${riderId}/accountable`, body);

    return response.data;
  }

  // ---------- Trainer ----------

  public async findTrainer(): Promise<IPerson[]> {
    const response = await this.instance.get(`/associations/persons/trainer`);

    return response.data;
  }

  // ---------- Trainer by horses ----------

  public async findTrainerByHorse(data: IFindAssociationHorsePersonByHorseIdRequest): Promise<IPerson> {
    const { horseId } = data;

    const response = await this.instance.get(`/associations/horses/${horseId}/trainer`);

    return response.data;
  }

  public async updateTrainerByHorse(dto: IUpsertAssociationHorsePersonDTO): Promise<IPerson> {
    const { horseId, ...body } = dto;

    const response = await this.instance.put(`/associations/horses/${horseId}/trainer`, body);

    return response.data;
  }

  // ---------- Trainer by riders ----------

  public async findTrainerByRider(data: IFindAssociationRiderPersonByRiderIdRequest): Promise<IPerson> {
    const { riderId } = data;

    const response = await this.instance.get(`/associations/riders/${riderId}/trainer`);

    return response.data;
  }

  public async updateTrainerByRider(dto: IUpsertAssociationRiderPersonDTO): Promise<IPerson> {
    const { riderId, ...body } = dto;

    const response = await this.instance.put(`/associations/riders/${riderId}/trainer`, body);

    return response.data;
  }
}

export const persons = (instance: AxiosInstance): Persons => new Persons(instance);
