import { AxiosInstance } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import { IHorsePayment } from '@modules/horses/types/AssociationHorsesPayments/payments';
import {
  ICreateHorsePaymentRequest,
  IDeleteHorsePaymentRequest,
  IFindHorsePaymentsRequest,
  IUpdateHorsePaymentRequest,
} from '@modules/horses/types/AssociationHorsesPayments/requests';

export class AssociationHorsesPayments {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Horse payments ----------

  async createPayment(data: ICreateHorsePaymentRequest): Promise<IHorsePayment> {
    const { horseId, ...body } = data;
    const response = await this.instance.post(`/associations/horses/${horseId}/payments`, body);

    return response.data;
  }

  async deletePayment(data: IDeleteHorsePaymentRequest): Promise<void> {
    const { horseId, horsePaymentId } = data;
    const response = await this.instance.delete(`/associations/horses/${horseId}/payments/${horsePaymentId}`);

    return response.data;
  }

  async find(dto: IFindHorsePaymentsRequest): Promise<IPaginateDTO<IHorsePayment>> {
    const { horseId, ...params } = dto;

    const response = await this.instance.get(`/associations/horses/${horseId}/payments`, { params });

    return response.data;
  }

  async updatePayment(data: IUpdateHorsePaymentRequest): Promise<IHorsePayment> {
    const { horseId, horsePaymentId, ...body } = data;
    const response = await this.instance.patch(`/associations/horses/${horseId}/payments/${horsePaymentId}`, body);

    return response.data;
  }
}

export const associationHorsesPayments = (instance: AxiosInstance): AssociationHorsesPayments =>
  new AssociationHorsesPayments(instance);
