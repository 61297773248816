export const Storage = {
  CURRENT_PROFILE: '@Equites:associations:profile:current',
  CREATE_ASSOCIATION_COMPANY_DATA: '@Equites:associations:companies:create:data',
  CREATE_ASSOCIATION_COMPANY_FILE: '@Equites:associations:companies:create:avatar:file',
  FIELDS_BALANCE_MOVEMENTS: '@Equites:associations:fields:balance_movements',
  LANGUAGE: '@Equites:associations:language',
  PERMISSIONS: '@Equites:associations:permissions',
  THEME: '@Equites:associations:theme',
  TOKENS: '@Equites:associations:tokens',
  VIEW_COMPANIES_LIST: '@Equites:associations:companies:list:view',
  VIEW_HORSES_LIST: '@Equites:associations:horses:list:view',
  VIEW_RIDERS_LIST: '@Equites:associations:riders:list:view',
};
