/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useParams, useSearchParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import { AssociationHorseByCompanySlug } from '../types/AssociationHorsesPublic/public';
import { FindAssociationHorsesByCompanySlugRequest } from '../types/AssociationHorsesPublic/requests';

type RouteParams = {
  slug: string;
};

export function useFetchAssociationsHorsesPublic() {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();

  const { slug = '' } = useParams<keyof RouteParams>();

  const params: FindAssociationHorsesByCompanySlugRequest = {
    companyId: urlSearchParams.get('companyId'),
    limit: Number(urlSearchParams.get('limit')),
    page: Number(urlSearchParams.get('page')),
    search: urlSearchParams.get('search'),
    slug,
    year: Number(urlSearchParams.get('year')),
  };

  // Init filters
  useEffectOnce(() => {
    setUrlSearchParams(state => {
      state.set('limit', '10');
      state.set('page', '1');
      state.set('year', new Date().getFullYear().toString());
      return state;
    });
  });

  return useQuery<IPaginateDTO<AssociationHorseByCompanySlug>>({
    enabled: Boolean(params.limit) && Boolean(params.page) && Boolean(params.year) && Boolean(params.slug),
    queryKey: ['public:association-horses:list', params],
    queryFn: () => CompaniesClient.associationHorsesPublic().find(params),
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 10, // 10 minutes
  });
}
