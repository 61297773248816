import { tv } from 'tailwind-variants';

export const chipVariants = tv({
  slots: {
    wrapper: 'flex items-center gap-3 rounded-full',
    text: 'flex-1',
    x: 'transition-colors',
  },
  variants: {
    color: {
      gray: {
        wrapper: 'bg-gray-500/20',
        text: 'text-gray-500',
        x: 'text-gray-500 hover:text-gray-500/50',
      },
      green: {
        wrapper: 'bg-green-500/20',
        text: 'text-green-500',
        x: 'text-green-500 hover:text-green-500/50',
      },
      red: {
        wrapper: 'bg-red-500/20',
        text: 'text-red-500',
        x: 'text-red-500 hover:text-red-500/50',
      },
      yellow: {
        wrapper: 'bg-yellow-600/20',
        text: 'text-yellow-600',
        x: 'text-yellow-600 hover:text-yellow-600/50',
      },
    },
    allWidth: {
      true: {
        wrapper: 'w-full',
      },
      false: {
        wrapper: 'w-fit',
      },
    },
    withImage: {
      true: {
        wrapper: 'p-1 pr-2',
        text: 'text-sm',
      },
      false: {
        wrapper: 'px-3 py-1',
        text: 'text-xs',
      },
    },
  },
  defaultVariants: {
    color: 'green',
    withImage: false,
  },
});
