import { IHorseAccountablePerson } from '@modules/persons/types/persons';
import { AxiosInstance } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import {
  IAssociationHorseAbout,
  IAssociationHorseSummary,
  ICountAssociationHorsesSummary,
} from '@modules/horses/types/AssociationHorses/horses';
import {
  CreateAssociationHorseRequest,
  DeleteAssociationHorseRequest,
  FindAssociationHorsesAbout,
  FindAssociationHorsesPaginatedRequest,
  UpdateAssociationHorseAboutRequest,
  UpdateAssociationHorseResponsibleRider,
} from '@modules/horses/types/AssociationHorses/requests';

type IFindSummaryResponse = Promise<IPaginateDTO<IAssociationHorseSummary>>;

export class AssociationsHorses {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Horses ----------

  public async create(data: CreateAssociationHorseRequest): Promise<IAssociationHorseSummary> {
    const response = await this.instance.post('/associations/horses', data);

    return response.data;
  }

  public async deleteById({ horseId }: DeleteAssociationHorseRequest): Promise<void> {
    await this.instance.delete(`/associations/horses/${horseId}`);
  }

  public async findAboutById(params: FindAssociationHorsesAbout): Promise<IAssociationHorseAbout> {
    const response = await this.instance.get(`/associations/horses/${params.horseId}`);

    return response.data;
  }

  public async findSummary(params: FindAssociationHorsesPaginatedRequest): IFindSummaryResponse {
    const response = await this.instance.get('/associations/horses', { params });

    return response.data;
  }

  public async update({ id, ...data }: UpdateAssociationHorseAboutRequest): Promise<IAssociationHorseAbout> {
    const response = await this.instance.put(`/associations/horses/${id}`, data);

    return response.data;
  }

  // ---------- Counter ----------

  public async counterSummary(): Promise<ICountAssociationHorsesSummary> {
    const response = await this.instance.get('/associations/horses/counter/total-affiliates-no-affiliates');

    return response.data;
  }

  // ---------- Responsible rider ----------

  public async updateResponsibleRider(data: UpdateAssociationHorseResponsibleRider): Promise<IHorseAccountablePerson> {
    const { horseId, riderId } = data;

    const response = await this.instance.put(`/associations/horses/${horseId}/responsible-rider`, { riderId });

    return response.data;
  }
}

export const associationHorses = (instance: AxiosInstance): AssociationsHorses => new AssociationsHorses(instance);
