import { AxiosInstance } from 'axios';

import { IConfiguration } from '@modules/configurations/types/Configurations/configurations';
import {
  IUpdateConfigurationsRequest,
  IUpdateImageReportsRequest,
  IUpdateImageReportsResponse,
} from '@modules/configurations/types/Configurations/requests';

export class Configuration {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async getConfigurations(): Promise<IConfiguration> {
    const response = await this.instance.get(`/configurations`);

    return response.data;
  }

  async updateConfigurations(data: IUpdateConfigurationsRequest): Promise<IConfiguration> {
    const response = await this.instance.patch(`/configurations`, data);

    return response.data;
  }

  async updateImageReports(data: IUpdateImageReportsRequest): Promise<IUpdateImageReportsResponse> {
    const formData = new FormData();
    formData.append('image', data.file);

    const headers = { 'Content-Type': 'multipart/form-data' };

    const response = await this.instance.put(`/configurations/image-reports`, formData, { headers });

    return response.data;
  }
}

export const configuration = (instance: AxiosInstance): Configuration => new Configuration(instance);
