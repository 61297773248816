import { FC, MouseEvent, ReactElement, useEffect, useRef } from 'react';

import { Slot } from '@radix-ui/react-slot';
import { twJoin } from 'tailwind-merge';

import { Text } from '../Text';
import { InputTextProvider } from './contexts/InputTextContext';
import { useInputText } from './hooks/useInputText';
import {
  IInputTextHelpTextProps,
  IInputTextInputRootProps,
  IInputTextRootProps,
  InputTextIconProps,
  InputTextInputProps,
} from './types';

// ------------------------------------------

/**
 * InputText Root - Wrapper to all input
 */
const InputTextRoot: FC<IInputTextRootProps> = (props): ReactElement => {
  const { children, className } = props;

  return (
    <InputTextProvider>
      <div className={twJoin('flex flex-col', className)}>{children}</div>
    </InputTextProvider>
  );
};

InputTextRoot.displayName = 'InputText.Root';

// ------------------------------------------

/**
 * InputText Input Root - Wrapper to input field, to use with icon or not
 */
const InputTextInputRoot: FC<IInputTextInputRootProps> = ({ children, hasError = false, className }): ReactElement => {
  const contentRef = useRef<HTMLDivElement>(null);

  const { isActive, deactivateInput } = useInputText();

  useEffect(() => {
    const handleClickOutside = (event: globalThis.MouseEvent): void => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (!(contentRef.current as any).contains(event.target)) {
        deactivateInput();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [deactivateInput]);

  return (
    <div
      ref={contentRef}
      data-is-active={isActive}
      data-has-error={hasError}
      className={twJoin(
        'flex h-11 w-full items-center rounded-lg border border-gray-200 px-4 transition-all data-[has-error=true]:border-red-500 data-[is-active=true]:border-green-500 dark:border-black-400 lg:h-10',
        className,
      )}
    >
      {children}
    </div>
  );
};

InputTextInputRoot.displayName = 'InputText.InputRoot';

// ------------------------------------------

/**
 * InputText Icon - Icon to show before input field
 */
const InputTextIcon: FC<InputTextIconProps> = ({ children }): ReactElement => {
  return <Slot className="text-gray-400">{children}</Slot>;
};

InputTextIcon.displayName = 'InputText.Icon';

// ------------------------------------------

/**
 * InputText HelpText - Text to show under input field with help or error messages
 */
const InputTextHelpText: IInputTextHelpTextProps = ({ children, className }): ReactElement => {
  return <Text className={twJoin('mt-1 block text-xs text-gray-400', className)}>{children}</Text>;
};

InputTextHelpText.displayName = 'InputText.HelpText';

// ------------------------------------------

/**
 * InputText Input - Input field
 */
const InputTextInput: FC<InputTextInputProps> = (props): ReactElement => {
  const { className, type = 'text', inputRef, onClick, ...inputProps } = props;

  const { activateInput } = useInputText();

  const handleInputClick = (event: MouseEvent<HTMLInputElement>): void => {
    if (onClick) {
      onClick(event);
    }

    activateInput();
  };

  return (
    <input
      ref={inputRef}
      type={type}
      onClick={handleInputClick}
      className={twJoin(
        'w-full flex-1 bg-transparent text-sm text-black-400 caret-green-500 outline-none placeholder:text-sm placeholder:text-gray-400 disabled:cursor-not-allowed disabled:text-gray-500 disabled:placeholder:text-gray-200 dark:text-white dark:placeholder:text-gray-500 dark:disabled:text-gray-700 dark:disabled:placeholder:text-black-400',
        className,
      )}
      {...inputProps}
    />
  );
};

InputTextInput.displayName = 'InputText.Input';

// ------------------------------------------

export const InputText = {
  Root: InputTextRoot,
  Input: InputTextInput,
  InputRoot: InputTextInputRoot,
  Icon: InputTextIcon,
  HelpText: InputTextHelpText,
};
