/* eslint-disable no-console */

import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteError } from 'react-router-dom';

import { SpinnerGap } from '@phosphor-icons/react';

import Icon from '@shared/assets/images/icon.webp';
import { Button } from '@shared/components/Button';
import { Text } from '@shared/components/Text';

const ERROR_MESSAGE = 'dynamically imported module';

export const ErrorPage = (): ReactElement => {
  const { t } = useTranslation('globals');

  const error = useRouteError() as { message: string };

  console.error({ error });

  if (error?.message && (error.message as string).toLocaleLowerCase().includes(ERROR_MESSAGE.toLocaleLowerCase())) {
    // setTimeout(() => {
    //   window.location.reload();
    // }, 3000);

    return (
      <section className="flex h-full flex-col items-center justify-center gap-4">
        <img src={Icon} alt="Equites" className="w-20" />

        <Text className="text-black-400 dark:text-white">{t('has_a_problem_to_load_call_equites_team')}</Text>

        <SpinnerGap size={16} className="animate-spin text-gray-500" />
      </section>
    );
  }

  return (
    <section className="flex h-full flex-col items-center justify-center gap-4">
      <img src={Icon} alt="Equites" className="w-20" />

      <Text className="text-black-400 dark:text-white">{t('has_a_problem_to_run_call_equites_team')}</Text>

      <Button asChild className="w-fit px-10">
        <Link to="/" className="text-center">
          {t('go_to_home')}
        </Link>
      </Button>
    </section>
  );
};
