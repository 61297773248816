import { AxiosInstance } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import { ICompany, ICompanySummary } from '@modules/companies/types/Companies/companies';
import {
  ICreateAssociationCompanyRequest,
  IDeleteAssociationCompanyByIdRequest,
  IFindAssociationCompaniesPaginatedRequest,
  IFindAssociationCompaniesSummaryRequest,
  IFindAssociationCompanyByIdRequest,
  IUpdateAssociationCompanyAvatarRequest,
  IUpdateAssociationCompanyRequest,
} from '@modules/companies/types/Companies/requests';

export class AssociationCompanies {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Companies ----------

  public async create(data: ICreateAssociationCompanyRequest): Promise<ICompany> {
    const response = await this.instance.post('/associations/companies', data);

    return response.data;
  }

  public async delete({ companyId }: IDeleteAssociationCompanyByIdRequest): Promise<void> {
    await this.instance.delete(`/associations/companies/${companyId}`);
  }

  public async findById(params: IFindAssociationCompanyByIdRequest): Promise<ICompany> {
    const response = await this.instance.get(`/associations/companies/${params.companyId}`);

    return response.data;
  }

  public async findPaginated(params: IFindAssociationCompaniesPaginatedRequest): Promise<IPaginateDTO<ICompany>> {
    const response = await this.instance.get(`/associations/companies`, { params });

    return response.data;
  }

  public async update(data: IUpdateAssociationCompanyRequest): Promise<ICompany> {
    const { id, ...body } = data;

    const response = await this.instance.patch(`/associations/companies/${id}`, body);

    return response.data;
  }

  // ---------- Summary ----------

  public async findSummary(params: IFindAssociationCompaniesSummaryRequest): Promise<ICompanySummary[]> {
    const response = await this.instance.get(`/associations/companies/summary`, { params });

    return response.data;
  }

  // ---------- Avatar ----------

  public async updateAvatar(data: IUpdateAssociationCompanyAvatarRequest): Promise<ICompanySummary> {
    const { companyId, file } = data;

    const formData = new FormData();
    formData.append('avatar', file);

    const response = await this.instance.put(`/associations/companies/${companyId}/avatar`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return response.data;
  }
}

export const associationCompanies = (instance: AxiosInstance): AssociationCompanies =>
  new AssociationCompanies(instance);
