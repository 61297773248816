import { FC, PropsWithChildren, ReactElement } from 'react';

import { QueryClientProvider } from '@tanstack/react-query';

import { queryClient } from '@shared/clients/http/ReactQuery';

import { AppAuthProvider } from '@modules/auth/contexts';

import { AppBalancesProvider } from '@modules/balances/contexts';

import { AppProvider } from '@modules/globals/contexts/AppContext';
import { LoaderProvider } from '@modules/globals/contexts/LoaderContext';
import { ThemeProvider } from '@modules/globals/contexts/ThemeContext';

import { AppNotificationsProvider } from '@modules/notifications/contexts';

import { AppProfileProvider } from '@modules/profiles/contexts';

export const EquitesAppProvider: FC<PropsWithChildren> = ({ children }): ReactElement => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <LoaderProvider>
          <AppProvider>
            <AppProfileProvider>
              <AppAuthProvider>
                <AppBalancesProvider>
                  <AppNotificationsProvider>{children}</AppNotificationsProvider>
                </AppBalancesProvider>
              </AppAuthProvider>
            </AppProfileProvider>
          </AppProvider>
        </LoaderProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};
