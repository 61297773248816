import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Horse } from '@phosphor-icons/react';

import { Text } from '@shared/components/Text';

const PublicHorseListEmptyStateComponent: FC = () => {
  const { t } = useTranslation('public-pages');

  return (
    <div className="flex w-full flex-col items-center justify-center">
      <div className="flex h-24 w-24 items-center justify-center rounded-full shadow-lg dark:border dark:border-black-400">
        <Horse size={68} className="text-green-500" />
      </div>

      <Text className="mb-2 mt-4 text-center text-lg font-semibold text-black-400 dark:text-white">
        {t('empty_state_horses_list_title')}
      </Text>

      <Text className="text-center text-sm font-light text-gray-400">{t('empty_state_horses_list_description')}</Text>
    </div>
  );
};

export const PublicHorseListEmptyState = memo(PublicHorseListEmptyStateComponent);
