import { ReactElement } from 'react';
import ReactPaginate from 'react-paginate';

import { CaretLeft, CaretRight, DotsThree } from '@phosphor-icons/react';

import { IPaginateProps } from './types';

export const Paginate = (props: IPaginateProps): ReactElement => {
  const {
    currentPage,
    onPageChange,
    pageCount,
    marginPagesDisplayed = 1,
    pageRangeDisplayed = 2,
    activeClassName,
    containerClassName,
  } = props;

  return (
    <div>
      <ReactPaginate
        activeClassName={activeClassName}
        breakLabel={<DotsThree size={14} weight="bold" className="text-black-400 dark:text-white" />}
        containerClassName={containerClassName}
        forcePage={currentPage}
        onPageChange={onPageChange}
        marginPagesDisplayed={marginPagesDisplayed}
        nextLabel={<CaretRight size={14} weight="bold" className="text-black-400 dark:text-white" />}
        pageCount={pageCount ?? 1}
        pageRangeDisplayed={pageRangeDisplayed}
        previousLabel={<CaretLeft size={14} weight="bold" className="text-black-400 dark:text-white" />}
      />
    </div>
  );
};
