import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Barn } from '@phosphor-icons/react';

import { Avatar } from '@shared/components/Avatar';
import { Text } from '@shared/components/Text';

import { useFetchAssociationCompanyPublic } from '@modules/companies/hooks/useFetchAssociationCompanyPublic';

export const PublicRiderListCompany = (): ReactElement => {
  const { t } = useTranslation('public-pages');

  const { data: association } = useFetchAssociationCompanyPublic();

  return (
    <section className="mt-10 flex flex-col items-center">
      <Avatar.Root mode="SQUARE" size="md">
        <Avatar.Content altImg={association?.name} icon={Barn} url={association?.logoUrl} />
      </Avatar.Root>

      <Text className="mb-2 mt-4 text-center text-xl font-bold text-black-400 dark:text-white">
        {association?.name}
      </Text>

      <Text className="text-center text-md text-gray-500 dark:text-gray-600">
        {t('athletes_affiliated_by_year', { year: new Date().getFullYear() })}
      </Text>
    </section>
  );
};
