import { ReactElement, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Eye } from '@phosphor-icons/react';

import { IPageSelected } from '@shared/components/Paginate/types';
import { Table } from '@shared/components/Table';
import { Text } from '@shared/components/Text';
import { Tooltip } from '@shared/components/Tooltip';

import { useFetchAssociationsHorsesPublic } from '@modules/horses/hooks/useFetchAssociationHorsesPublic';
import { AssociationHorseByCompanySlug } from '@modules/horses/types/AssociationHorsesPublic/public';

import { IModalPublicHorseDetailsHandles, ModalPublicHorseDetails } from '../ModalPublicHorseDetails';
import { PublicHorseListEmptyState } from '../PublicHorseListEmptyState';

export const PublicHorseListItems = (): ReactElement => {
  const modalPublicHorseDetailsRef = useRef<IModalPublicHorseDetailsHandles>(null);

  const { t } = useTranslation('public-pages');

  const [, setUrlSearchParams] = useSearchParams();

  const { data: horses } = useFetchAssociationsHorsesPublic();

  const [horseSelected, setHorseSelected] = useState({} as AssociationHorseByCompanySlug);

  const openModalPublicHorseDetails = (horse: AssociationHorseByCompanySlug): void => {
    setHorseSelected(horse);
    modalPublicHorseDetailsRef.current?.open();
  };

  const handlePageChange = (data: IPageSelected): void => {
    setUrlSearchParams(state => {
      state.set('page', (data.selected + 1).toString());
      return state;
    });
  };

  return (
    <>
      <section className="mt-10 flex w-full justify-center">
        {horses?.totalItems ? (
          <Table.Root>
            <Table.Table>
              <Table.Head>
                <Table.HeadRow>
                  <th aria-label="id">
                    <Text className="font-semibold text-green-500">
                      {`ID ${horses.items[0].registrationNumber.split('-')[0]}`}
                    </Text>
                  </th>

                  <th aria-label="name">
                    <Text className="font-semibold text-green-500">{t('name')}</Text>
                  </th>

                  <th aria-label="categories">
                    <Text className="font-semibold text-green-500">{t('categories')}</Text>
                  </th>

                  <th aria-label="entity">
                    <Text className="font-semibold text-green-500">{t('entity')}</Text>
                  </th>

                  <th aria-label="actions">{''}</th>
                </Table.HeadRow>
              </Table.Head>
              <Table.Body>
                {horses.items.map(horse => (
                  <Table.BodyRow key={horse.registrationNumber}>
                    <td aria-label="id">
                      <Text className="text-black-400 dark:text-white">{horse.registrationNumber.split('-')[1]}</Text>
                    </td>

                    <td aria-label="name">
                      <Text className="text-black-400 dark:text-white">{horse.name}</Text>
                    </td>

                    <td aria-label="categories">
                      <Text className="text-black-400 dark:text-white">
                        {horse.category === 'HORSE' ? t('horse') : horse.category}
                      </Text>
                    </td>

                    <td aria-label="entity">
                      <Text className="text-black-400 dark:text-white">{horse.company.slug}</Text>
                    </td>

                    <td aria-label="actions">
                      <Tooltip.Root>
                        <Tooltip.Trigger>
                          <Eye
                            size={16}
                            className="cursor-pointer text-gray-500 dark:text-gray-600"
                            onClick={() => openModalPublicHorseDetails(horse)}
                          />
                        </Tooltip.Trigger>
                        <Tooltip.Content text={t('view_details')} />
                      </Tooltip.Root>
                    </td>
                  </Table.BodyRow>
                ))}
              </Table.Body>
            </Table.Table>
            <Table.TotalItems totalItems={horses.totalItems || 0} />
            <Table.Pagination onPageChange={handlePageChange} pageCount={horses.qtPages || 0} />
          </Table.Root>
        ) : (
          <PublicHorseListEmptyState />
        )}
      </section>

      {/* Modals */}
      <ModalPublicHorseDetails ref={modalPublicHorseDetailsRef} horse={horseSelected} />
    </>
  );
};
