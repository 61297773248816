import { FC, PropsWithChildren, ReactElement } from 'react';
import { ScrollRestoration } from 'react-router-dom';
import PullToRefresh from 'react-simple-pull-to-refresh';

import { ArrowDown, SpinnerGap } from '@phosphor-icons/react';
import { motion } from 'framer-motion';

import { useApp } from '@modules/globals/hooks/useApp';

import { IPageProps } from './types';

export const Page: FC<PropsWithChildren<IPageProps>> = (props): ReactElement => {
  const { children, onRefresh, scrollRestorationIsEnabled = true, ...pageProps } = props;

  const { ptrIsEnabled } = useApp();

  const pageMotion = {
    animate: { opacity: 1, transition: { duration: 0.1 } },
    exit: { opacity: 0, transition: { duration: 0.1 } },
  };

  const refreshPage = async (): Promise<void> => {
    if (onRefresh) {
      await onRefresh();
    }
  };

  return (
    <PullToRefresh
      isPullable={ptrIsEnabled && Boolean(onRefresh)}
      onRefresh={refreshPage}
      pullingContent={<ArrowDown size={24} className="mx-auto text-gray-500" />}
      refreshingContent={<SpinnerGap size={24} className="mx-auto animate-spin text-gray-500" />}
      pullDownThreshold={90}
      maxPullDownDistance={90}
      resistance={2.5}
    >
      <>
        {scrollRestorationIsEnabled && <ScrollRestoration />}

        <motion.main initial="initial" animate="animate" exit="exit" variants={pageMotion} {...pageProps}>
          {children}
        </motion.main>
      </>
    </PullToRefresh>
  );
};
