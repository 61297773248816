import { createContext, FC, PropsWithChildren, useMemo, useState } from 'react';

import { IInputTextAreaContext } from '../types';

const InputTextAreaContext = createContext({} as IInputTextAreaContext);

const InputTextAreaProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isActive, setIsActive] = useState(false);

  const contextValue = useMemo<IInputTextAreaContext>(
    () => ({ isActive, activateInput: () => setIsActive(true), deactivateInput: () => setIsActive(false) }),
    [isActive],
  );

  return <InputTextAreaContext.Provider value={contextValue}>{children}</InputTextAreaContext.Provider>;
};

export { InputTextAreaContext, InputTextAreaProvider };
