import { IAffiliation } from '@modules/affiliations/types/Affiliations/affiliations';
import {
  ICreateRiderAffiliationRequest,
  IDeleteRiderAffiliationByIdRequest,
  IFindRiderAffiliationsPaginatedRequest,
  IUpdateRiderAffiliationRequest,
} from '@modules/affiliations/types/Affiliations/requests';
import { AxiosInstance } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

export class AssociationRidersAffiliations {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Riders affiliations ----------

  public async create(data: ICreateRiderAffiliationRequest): Promise<IAffiliation> {
    const { associationRiderId: riderId, ...body } = data;

    const response = await this.instance.post(`/associations/riders/${riderId}/affiliations`, body);

    return response.data;
  }

  public async delete(data: IDeleteRiderAffiliationByIdRequest): Promise<void> {
    const { associationRiderId, affiliationId } = data;

    await this.instance.delete(`/associations/riders/${associationRiderId}/affiliations/${affiliationId}`);
  }

  public async findPaginated(data: IFindRiderAffiliationsPaginatedRequest): Promise<IPaginateDTO<IAffiliation>> {
    const { associationRiderId: riderId, ...params } = data;

    const response = await this.instance.get(`/associations/riders/${riderId}/affiliations`, { params });

    return response.data;
  }

  public async update(data: IUpdateRiderAffiliationRequest): Promise<IAffiliation> {
    const { associationRiderId: riderId, affiliationId, ...body } = data;

    const response = await this.instance.put(`/associations/riders/${riderId}/affiliations/${affiliationId}`, body);

    return response.data;
  }
}

export const associationRidersAffiliations = (instance: AxiosInstance): AssociationRidersAffiliations =>
  new AssociationRidersAffiliations(instance);
