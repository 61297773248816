import { FC, MouseEvent, ReactElement, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { PatternFormat } from 'react-number-format';

import { Slot } from '@radix-ui/react-slot';
import { AnimatePresence, useAnimate } from 'framer-motion';
import { twJoin, twMerge } from 'tailwind-merge';

import { CalendarDay } from '../CalendarDay';
import { Text } from '../Text';
import { InputMaskProvider } from './contexts/InputMaskContext';
import { useInputMask } from './hooks/useInputMask';
import {
  IInputMaskHelpTextProps,
  IInputMaskInputRootProps,
  IInputMaskRootProps,
  InputMaskCalendarProps,
  InputMaskIconProps,
  InputMaskInputProps,
} from './types';

// ------------------------------------------

/**
 * InputMask Root - Wrapper to all input
 */
const InputMaskRoot: FC<IInputMaskRootProps> = (props): ReactElement => {
  const { children, className } = props;

  return (
    <InputMaskProvider>
      <div className={twJoin('flex flex-col', className)}>{children}</div>
    </InputMaskProvider>
  );
};

InputMaskRoot.displayName = 'InputMask.Root';

// ------------------------------------------

/**
 * InputMask Input Root - Wrapper to input field, to use with icon or not
 */
const InputMaskInputRoot: FC<IInputMaskInputRootProps> = (props): ReactElement => {
  const { children, className, hasError = false } = props;

  const inputRootRef = useRef<HTMLDivElement>(null);

  const { closeCalendar, deactivateInput, isActive } = useInputMask();

  // Close calendar when clicked outside content
  useEffect(() => {
    const handleClickOutside = (event: globalThis.MouseEvent): void => {
      if (!inputRootRef.current?.contains(event.target as Node)) {
        closeCalendar();
        deactivateInput();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeCalendar, deactivateInput]);

  return (
    <div
      ref={inputRootRef}
      data-is-active={isActive}
      data-has-error={hasError}
      className={twJoin(
        'relative flex h-11 w-full items-center rounded-lg border border-gray-200 px-4 transition-all data-[has-error=true]:border-red-500 data-[is-active=true]:border-green-500 dark:border-black-400 lg:h-10',
        className,
      )}
    >
      {children}
    </div>
  );
};

InputMaskInputRoot.displayName = 'InputMask.InputRoot';

// ------------------------------------------

/**
 * InputMask Icon - Icon to show before input field
 */
const InputMaskIcon: FC<InputMaskIconProps> = ({ children }): ReactElement => {
  return <Slot className="text-gray-400">{children}</Slot>;
};

InputMaskIcon.displayName = 'InputMask.Icon';

// ------------------------------------------

/**
 * InputMask HelpText - Text to show under input field with help or error messages
 */
const InputMaskHelpText: IInputMaskHelpTextProps = ({ children, className }): ReactElement => {
  return <Text className={twJoin('mt-1 block text-xs text-gray-400', className)}>{children}</Text>;
};

InputMaskHelpText.displayName = 'InputMask.HelpText';

// ------------------------------------------

/**
 * InputMask Calendar - Input date field
 */
const InputMaskCalendar: FC<InputMaskCalendarProps> = (props): ReactElement | null => {
  const { defaultDate = new Date(), onSelectDate } = props;

  const [scope, animate] = useAnimate<HTMLDivElement>();

  const { isCalendarOpened, closeCalendar } = useInputMask();

  const [classes, setClasses] = useState('');

  const handleSelectDate = (date?: Date): void => {
    if (date) {
      if (onSelectDate) {
        onSelectDate(date);
      }
    }

    closeCalendar();
  };

  useLayoutEffect(() => {
    if (isCalendarOpened) {
      const enterAnimation = async (): Promise<void> => {
        const elementRect = scope.current?.getBoundingClientRect();

        if (elementRect) {
          const spaceAbove = elementRect.top;
          const spaceBelow = window.innerHeight - elementRect.bottom;

          const shouldOpenAbove = spaceBelow <= 0 && spaceBelow < spaceAbove;

          if (shouldOpenAbove) setClasses(current => `${current} bottom-11 top-auto`);

          const spaceRight = elementRect.right;
          const spaceLeft = window.innerWidth - elementRect.left;

          const shouldOpenRight = spaceLeft < spaceRight;

          if (shouldOpenRight) setClasses(current => `${current} right-0 left-auto`);

          await animate(scope.current, { opacity: 1, y: shouldOpenAbove ? -8 : 8 }, { type: 'tween' });
          await animate(scope.current, { opacity: 1, y: 0 }, { type: 'tween' });
        }
      };

      enterAnimation();
    } else {
      setClasses('');
    }
  }, [animate, isCalendarOpened, scope]);

  return (
    <AnimatePresence mode="wait">
      {isCalendarOpened ? (
        <div
          ref={scope}
          className={twMerge(
            'absolute left-0 top-11 z-10 w-full min-w-60 rounded-lg border border-gray-200 bg-background-light shadow-lg dark:border-black-400 dark:bg-background-dark',
            classes,
          )}
        >
          <CalendarDay
            mode="single"
            selected={defaultDate}
            onSelect={handleSelectDate}
            showOutsideDays
            classNameRoot="w-full"
            defaultMonth={defaultDate}
          />
        </div>
      ) : null}
    </AnimatePresence>
  );
};

InputMaskCalendar.displayName = 'InputMask.Calendar';

// ------------------------------------------

/**
 * InputMask Input - Input field
 */
const InputMaskInput: FC<InputMaskInputProps> = (props): ReactElement => {
  const { className, getInputRef, withCalendar = false, defaultDate, onSelectDate, onClick, ...inputProps } = props;

  const { openCalendar, activateInput } = useInputMask();

  const handleClick = (event: MouseEvent<HTMLInputElement>): void => {
    activateInput();

    if (onClick) {
      onClick(event);
    }

    if (withCalendar) {
      openCalendar();
    }
  };

  return (
    <>
      <PatternFormat
        mask=" "
        getInputRef={getInputRef}
        valueIsNumericString
        onClick={handleClick}
        className={twJoin(
          'w-full flex-1 bg-transparent text-sm text-black-400 caret-green-500 outline-none placeholder:text-sm placeholder:text-gray-400 disabled:cursor-not-allowed disabled:text-gray-500 disabled:placeholder:text-gray-200 dark:text-white dark:placeholder:text-gray-500 dark:disabled:text-gray-700 dark:disabled:placeholder:text-black-400',
          className,
        )}
        {...inputProps}
      />

      {withCalendar && <InputMaskCalendar defaultDate={defaultDate} onSelectDate={onSelectDate} />}
    </>
  );
};

InputMaskInput.displayName = 'InputMask.Input';

export const InputMask = {
  Root: InputMaskRoot,
  Input: InputMaskInput,
  InputRoot: InputMaskInputRoot,
  Icon: InputMaskIcon,
  HelpText: InputMaskHelpText,
};
