import { createContext, FC, PropsWithChildren, ReactElement, useMemo } from 'react';
import { FormProvider, FormProviderProps } from 'react-hook-form';

export type EquitesFormContextProps = { isLoading: boolean };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props = FormProviderProps<any> & { isLoading: boolean };

export const EquitesFormContext = createContext({} as EquitesFormContextProps);

export const EquitesFormProvider: FC<PropsWithChildren<Props>> = (props): ReactElement => {
  const { children, isLoading = false, ...formProps } = props;

  const contextValue = useMemo(() => ({ isLoading }), [isLoading]);

  return (
    <EquitesFormContext.Provider value={contextValue}>
      <FormProvider {...formProps}>{children}</FormProvider>
    </EquitesFormContext.Provider>
  );
};
