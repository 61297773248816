import { FC, MouseEvent, ReactElement, useEffect, useRef } from 'react';
import { NumericFormat } from 'react-number-format';

import { Slot } from '@radix-ui/react-slot';
import { twMerge } from 'tailwind-merge';

import { Text } from '../Text';
import { InputNumberProvider } from './contexts/InputNumberContext';
import { useInputNumber } from './hooks/useInputNumber';
import {
  IInputNumberHelpTextProps,
  IInputNumberInputRootProps,
  IInputNumberRootProps,
  InputNumberIconProps,
  InputNumberInputProps,
} from './types';

// ------------------------------------------

/**
 * InputNumber Root - Wrapper to all input
 */
const InputNumberRoot: FC<IInputNumberRootProps> = (props): ReactElement => {
  const { children, className } = props;

  return (
    <InputNumberProvider>
      <div className={twMerge('flex flex-col', className)}>{children}</div>
    </InputNumberProvider>
  );
};

InputNumberRoot.displayName = 'InputNumber.Root';

// ------------------------------------------

/**
 * InputNumber Input Root - Wrapper to input field, to use with icon or not
 */
const InputNumberInputRoot: FC<IInputNumberInputRootProps> = (props): ReactElement => {
  const { children, className, hasError = false } = props;

  const contentRef = useRef<HTMLDivElement>(null);

  const { isActive, deactivateInput } = useInputNumber();

  useEffect(() => {
    const handleClickOutside = (event: globalThis.MouseEvent): void => {
      if (!contentRef.current?.contains(event.target as Node)) {
        deactivateInput();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [deactivateInput]);

  return (
    <div
      ref={contentRef}
      data-is-active={isActive}
      data-has-error={hasError}
      className={twMerge(
        'flex h-11 w-full items-center rounded-lg border border-gray-200 px-4 transition-all data-[has-error=true]:border-red-500 data-[is-active=true]:border-green-500 dark:border-black-400 lg:h-10',
        className,
      )}
    >
      {children}
    </div>
  );
};

InputNumberInputRoot.displayName = 'InputNumber.InputRoot';

// ------------------------------------------

/**
 * InputNumber Icon - Icon to show before input field
 */
const InputNumberIcon: FC<InputNumberIconProps> = ({ children }): ReactElement => {
  return <Slot className="text-gray-400">{children}</Slot>;
};

InputNumberIcon.displayName = 'InputNumber.Icon';

// ------------------------------------------

/**
 * InputNumber HelpText - Text to show under input field with help or error messages
 */
const InputNumberHelpText: IInputNumberHelpTextProps = ({ children, className }): ReactElement => {
  return <Text className={twMerge('mt-1 block text-xs text-gray-400', className)}>{children}</Text>;
};

InputNumberHelpText.displayName = 'InputNumber.HelpText';

// ------------------------------------------

/**
 * InputNumber Input - Input field
 */
const InputNumberInput = ({ className, getInputRef, onClick, ...props }: InputNumberInputProps): ReactElement => {
  const { activateInput } = useInputNumber();

  const handleInputClick = (event: MouseEvent<HTMLInputElement>): void => {
    if (onClick) {
      onClick(event);
    }

    activateInput();
  };

  return (
    <NumericFormat
      getInputRef={getInputRef}
      valueIsNumericString
      allowNegative={false}
      thousandSeparator="."
      decimalSeparator=","
      type="tel"
      onClick={handleInputClick}
      className={twMerge(
        'w-full flex-1 bg-transparent text-sm text-black-400 caret-green-500 outline-none placeholder:text-sm placeholder:text-gray-400 disabled:cursor-not-allowed disabled:text-gray-500 disabled:placeholder:text-gray-200 dark:text-white dark:placeholder:text-gray-500 dark:disabled:text-gray-700 dark:disabled:placeholder:text-black-400',
        className,
      )}
      {...props}
    />
  );
};

InputNumberInput.displayName = 'InputNumber.Input';

// ------------------------------------------

export const InputNumber = {
  Root: InputNumberRoot,
  Input: InputNumberInput,
  InputRoot: InputNumberInputRoot,
  Icon: InputNumberIcon,
  HelpText: InputNumberHelpText,
};
