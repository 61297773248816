import { createContext, FC, PropsWithChildren, useMemo, useState } from 'react';

import { IInputTextContext } from '../types';

const InputTextContext = createContext({} as IInputTextContext);

const InputTextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isActive, setIsActive] = useState(false);

  const contextValue = useMemo<IInputTextContext>(
    () => ({ isActive, activateInput: () => setIsActive(true), deactivateInput: () => setIsActive(false) }),
    [isActive],
  );

  return <InputTextContext.Provider value={contextValue}>{children}</InputTextContext.Provider>;
};

export { InputTextContext, InputTextProvider };
