import { AxiosInstance } from 'axios';
import fileDownload from 'js-file-download';

import { ExportAssociationHorsesRequest } from '@modules/horses/types/AssociationHorses/requests';

export class AssociationsHorsesReports {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Export ----------

  public async exportXlsx(params: ExportAssociationHorsesRequest): Promise<void> {
    const url = `/associations/horses/reports/summary/xlsx/export`;

    const { data } = await this.instance.get(url, { params, responseType: 'blob' });

    fileDownload(data, `horses.xlsx`);
  }
}

export const associationHorsesReports = (instance: AxiosInstance): AssociationsHorsesReports =>
  new AssociationsHorsesReports(instance);
