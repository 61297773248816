import { tv } from 'tailwind-variants';

export const switchVariants = tv({
  base: '',
  slots: {
    root: 'relative inline-flex h-9 min-w-16 cursor-pointer rounded-full border transition-colors duration-200 ease-in-out disabled:cursor-not-allowed lg:h-7 lg:min-w-14',
    thumb:
      'my-auto inline-block h-8 w-8 transform rounded-full bg-white shadow-lg transition duration-200 ease-in-out aria-disabled:cursor-not-allowed aria-disabled:shadow-none lg:h-6 lg:w-6',
  },
  variants: {
    checked: {
      true: {
        root: 'border-green-500 bg-green-500/40 disabled:border-green-200 disabled:bg-green-50 disabled:dark:border-green-700 disabled:dark:bg-green-800',
        thumb: 'translate-x-[29px] aria-disabled:bg-gray-200/90 aria-disabled:dark:bg-gray-500',
      },
      false: {
        root: 'border-gray-500 bg-gray-200 aria-disabled:bg-gray-200/50 disabled:border-gray-400/50 dark:bg-gray-600/50 disabled:dark:border-gray-800 disabled:dark:bg-gray-900',
        thumb: 'translate-x-[1px] aria-disabled:dark:bg-gray-600',
      },
    },
  },
});
