import { ReactElement } from 'react';

import { Page } from '@shared/components/Page';

import { PublicRiderListCompany } from '@modules/riders/components/PublicRidersList/PublicRiderListCompany';
import { PublicRiderListFilter } from '@modules/riders/components/PublicRidersList/PublicRiderListFilter';
import { PublicRiderListFooter } from '@modules/riders/components/PublicRidersList/PublicRiderListFooter';
import { PublicRiderListHeader } from '@modules/riders/components/PublicRidersList/PublicRiderListHeader';
import { PublicRiderListItems } from '@modules/riders/components/PublicRidersList/PublicRiderListItems';
import { useFetchAssociationsRidersPublic } from '@modules/riders/hooks/useFetchAssociationsRidersPublic';

export const PublicRidersList = (): ReactElement => {
  const { refetch } = useFetchAssociationsRidersPublic();

  const onRefresh = async (): Promise<void> => {
    await refetch();
  };

  return (
    <Page className="flex h-full flex-col px-4 py-8 lg:p-10" onRefresh={onRefresh}>
      <PublicRiderListHeader />
      <PublicRiderListCompany />
      <PublicRiderListFilter />
      <PublicRiderListItems />
      <PublicRiderListFooter />
    </Page>
  );
};
