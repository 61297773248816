import { tv } from 'tailwind-variants';

export const dividerVariants = tv({
  base: 'border-none',
  variants: {
    color: {
      gray: 'bg-black-400 bg-opacity-10 dark:bg-black-400',
      green: 'bg-green-500',
      red: 'bg-red-500',
    },
    mode: {
      full: 'mt-8 h-[1px] w-full',
      short: 'mt-2 h-[2px] w-12',
    },
  },
});
