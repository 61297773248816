import { tv } from 'tailwind-variants';

export const selectMultiInputChevronVariants = tv({
  base: 'ml-2 transition-all',
  variants: {
    disable: {
      true: 'fill-gray-400 dark:fill-black-400',
      false: 'fill-black-400 dark:fill-gray-400',
    },
    isOpened: {
      true: 'rotate-[270deg]',
      false: 'rotate-90',
    },
  },
});
