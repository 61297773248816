import 'react-day-picker/dist/style.css';
import './styles/index.css';

import { ReactElement } from 'react';
import { DayPicker, DayPickerSingleProps } from 'react-day-picker';

import { Locale } from 'date-fns';
import { ptBR, enUS } from 'date-fns/locale';
import { twJoin } from 'tailwind-merge';

import { useProfile } from '@modules/profiles/hooks/useProfile';

import { LockComponent } from '../LockComponent';

interface ICalendarDayProps extends DayPickerSingleProps {
  classNameRoot?: string;
  lockComponent?: boolean;
}

export const CalendarDay = (props: ICalendarDayProps): ReactElement => {
  const { classNameRoot, lockComponent, ...calendarProps } = props;

  const { currentLanguage } = useProfile();

  const getLocale = (): Locale => {
    switch (currentLanguage) {
      case 'pt-BR':
        return ptBR;
      case 'en-US':
        return enUS;
      default:
        return ptBR;
    }
  };

  return (
    <div
      className={twJoin(
        'relative flex items-center justify-center rounded-lg border-black-400 shadow-lg dark:border',
        classNameRoot,
      )}
    >
      {lockComponent && <LockComponent />}

      <DayPicker locale={getLocale()} className="equites__calendar_day_picker" {...calendarProps} />
    </div>
  );
};
