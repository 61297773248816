import { AxiosInstance } from 'axios';

import { IAssociationHorseIdentification } from '@modules/horses/types/AssociationHorsesIdentification/identification';
import {
  IDeleteAssociationHorseAttachmentRequest,
  IFindIdentificationByHorseIdRequest,
  IUploadAssociationHorseAttachmentRequest,
  IUpsertAssociationHorseIdentificationRequest,
} from '@modules/horses/types/AssociationHorsesIdentification/requests';

export class AssociationHorsesIdentifications {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Horse identification ----------

  async find(dto: IFindIdentificationByHorseIdRequest): Promise<IAssociationHorseIdentification> {
    const { horseId } = dto;

    const response = await this.instance.get(`/associations/horses/${horseId}/identification`);

    return response.data;
  }

  async update(data: IUpsertAssociationHorseIdentificationRequest): Promise<IAssociationHorseIdentification> {
    const { horseId, ...body } = data;

    const response = await this.instance.put(`/associations/horses/${horseId}/identification`, body);

    return response.data;
  }

  // ---------- Passport attachments ----------

  async uploadAttachment(data: IUploadAssociationHorseAttachmentRequest): Promise<IAssociationHorseIdentification> {
    const { horseId, files } = data;

    const formData = new FormData();
    files.map(file => formData.append('attachment', file));

    const url = `/associations/horses/${horseId}/identification/attachments/passport`;

    const response = await this.instance.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } });

    return response.data;
  }

  async deleteAttachment(data: IDeleteAssociationHorseAttachmentRequest): Promise<void> {
    const { attachmentId, horseId } = data;

    const url = `/associations/horses/${horseId}/identification/attachments/passport/${attachmentId}`;

    await this.instance.delete(url);
  }
}

export const associationHorsesIdentifications = (instance: AxiosInstance): AssociationHorsesIdentifications =>
  new AssociationHorsesIdentifications(instance);
