import { forwardRef, ForwardRefRenderFunction, ReactElement, useCallback, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Barn, Horse, X } from '@phosphor-icons/react';
import { format, parseISO } from 'date-fns';

import { Avatar } from '@shared/components/Avatar';
import { Chip } from '@shared/components/Chip';
import { Divider } from '@shared/components/Divider';
import { ModalDefault } from '@shared/components/ModalDefault';
import { Text } from '@shared/components/Text';

import { useFetchAssociationCompanyPublic } from '@modules/companies/hooks/useFetchAssociationCompanyPublic';

import { AssociationHorseByCompanySlug } from '@modules/horses/types/AssociationHorsesPublic/public';

type IProps = {
  horse: AssociationHorseByCompanySlug;
};

export interface IModalPublicHorseDetailsHandles {
  open: () => void;
  close: () => void;
}

type IModalPublicHorseDetails = ForwardRefRenderFunction<IModalPublicHorseDetailsHandles, IProps>;

const ModalPublicHorseDetailsComponent: IModalPublicHorseDetails = ({ horse }, ref): ReactElement => {
  const { t } = useTranslation('public-pages');

  const { data: association } = useFetchAssociationCompanyPublic();

  const [visible, setVisible] = useState(false);

  const open = useCallback(() => {
    setVisible(true);
  }, []);

  const close = useCallback(() => {
    setVisible(false);
  }, []);

  useImperativeHandle(ref, () => {
    return { open, close };
  });

  return (
    <ModalDefault visible={visible} close={close}>
      {horse.name ? (
        <div className="relative flex flex-col items-center justify-center overflow-visible lg:w-96">
          <X
            size={16}
            className="absolute right-0 top-0 cursor-pointer text-gray-500 dark:text-gray-600"
            onClick={close}
          />

          <Avatar.Root mode="SQUARE" size="md">
            <Avatar.Content altImg={association?.name} url={association?.logoUrl} icon={Barn} />
          </Avatar.Root>

          <section className="mt-1 flex w-full flex-col items-center gap-2 rounded-lg p-2 shadow-lg">
            <Horse size={40} className="text-green-500" />
            <Text className="text-xl font-bold text-black-400 dark:text-white">{horse.name}</Text>
            <Text className="text-xs font-semibold text-gray-500 dark:text-gray-600">
              {`ID ${association?.slug}: ${horse.registrationNumber.split('-')[1]}`}
            </Text>
          </section>

          {horse.company.city && (
            <section className="mt-6 flex flex-col items-center gap-1">
              <Text className="text-xs text-gray-500 dark:text-gray-600">{t('location')}</Text>
              <Text className="font-bold text-black-400 dark:text-white">{`${horse.company.city}/${horse.company.state}`}</Text>
            </section>
          )}

          {horse.category && (
            <ul className="mt-6 flex flex-wrap justify-center gap-1">
              <li>
                <Chip color="green" text={horse.category === 'HORSE' ? t('horse') : horse.category} />
              </li>
            </ul>
          )}

          <Divider mode="full" color="gray" className="my-4" />

          <section className="flex flex-col items-center gap-2">
            <div className="flex items-center justify-center gap-2">
              <Avatar.Root mode="ROUNDED" size="xxs">
                <Avatar.Content altImg={horse.company.name} url={horse.company.logoUrl} icon={Barn} />
              </Avatar.Root>

              <Text className="text-lg font-bold text-black-400 dark:text-white">{horse.company.name}</Text>
            </div>

            <Text className="text-xs text-gray-500 dark:text-gray-600">
              {`${t('horse_affiliated_until', { date: format(parseISO(horse.affiliationDue), 'dd/MM/yyyy') })}`}
            </Text>
          </section>
        </div>
      ) : (
        <Text>{t('loading')}</Text>
      )}
    </ModalDefault>
  );
};

export const ModalPublicHorseDetails = forwardRef(ModalPublicHorseDetailsComponent);
